import { message } from "antd";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { objectDetailSelector } from "redux/modules/common/building/object/nowObject";
import { ISectionPlan } from "redux/modules/common/building/processTypes";
import { projectActions } from "redux/modules/common/building/project/actions";

import Expandable from "../../../UI/atoms/_TODO/Expandable/Expandable";
import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";

import ClosureDates from "../../../../shared/ui/inputs/ClosureDates/ClosureDates";
import useClosureDates from "../../../../shared/ui/inputs/ClosureDates/useClosureDates";
import DoubleInput, { Measure } from "../../../../shared/ui/inputs/DoubleInput/DoubleInput";
import Executors from "../../../../widgets/Executors/Executors";
import useExecutors from "../../../../widgets/Executors/useExecutors";
import { useEditSectionPlan } from "../useEditSectionPlan";
import ButtonBase from "shared/ui/controls/ButtonBase";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";
import DeletePlan from "widgets/DeletePlan/DeletePlan";
import DeleteSectionPlan from "widgets/DeletePlan/DeleteSectionPlan";

import { dropNonSignificantZeros } from "../../../../utils/formatters/dropNonSignificantZeros";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./../ManufacturingPlanSectionModal.module.scss";

interface IProps {
  onClose: () => void;
  planData: ISectionPlan;
  dataRefetcher: () => void;
  objectId: string;
  onEditCallback?: () => void;
  plannedAmount?: number;
  plannedPercent?: number;
  budgetData: string;
  onCloseWholeModal?: () => void;
}

const EditSectionPlan: React.FC<IProps> = ({
  onClose,
  planData,
  dataRefetcher,
  objectId,
  onEditCallback,
  plannedPercent,
  plannedAmount,
  budgetData,
  onCloseWholeModal,
}) => {
  const isNew_close_dates = useSelector(objectDetailSelector)?.new_close_dates;
  const [canSave, setCanSave] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const {
    closureDates,
    addNewClosureDate,
    editClosureDate,
    removeClosureDate,
    deleteSectionClosureDates,
    createSectionClosureDates,
    updateSectionClosureDates,
    validateClosureDates,
    validateClosureDatesPercents,
    fillMissedPercents,
  } = useClosureDates({
    defaultClosureDates: planData?.close_dates,
    objectId,
    isOpen: true,
    sectionId: planData.id,
    isEditing: true,
  });

  const {
    onEdit,
    onDelete,
    onAddExecutor,
    availableRanksByPosts,
    availablePosts,
    executors,
    createSectionExecutors,
    deleteSectionExecutors,
    validateExecutors,
  } = useExecutors({
    isOpen: true,
    defaultExecutors: planData?.posts || [],
  });

  const { dateEnd, dateStart, setDateEnd, setDateStart, saveHandler } = useEditSectionPlan(
    planData,
    dataRefetcher,
    onClose,
    objectId,
    onEditCallback
  );

  const dispatch = useDispatch();
  const handleSaveClick = async () => {
    if (!validateClosureDates()) {
      message.warn("Подтвердите все даты закрытия");
      return;
    }
    if (!validateExecutors()) {
      message.warn("Подтвердите всех исполнителей");
      return;
    }
    if (isNew_close_dates && !validateClosureDatesPercents()) {
      message.warn("Введите корректные проценты");
      return;
    }

    if (!canSave) {
      let isPassingValidation = false;

      const percentSum = +dropNonSignificantZeros(
        /* @ts-ignore */
        closureDates.reduce((acc, curr) => acc + +curr.user_percent, 0),
        8
      );
      let budgetSum = 0;

      closureDates.forEach((date) => {
        if (date.strategy === 2) {
          /* @ts-ignore */
          budgetSum += (+date.user_percent / 100) * +budgetData;
        }
      });

      if (percentSum > 100) {
        let formattedPercents = Number(+percentSum - 100).toString();
        if (formattedPercents.includes("e")) {
          formattedPercents = Number(formattedPercents).toFixed(8);
        } else {
          /* @ts-ignore */
          formattedPercents = dropNonSignificantZeros(formattedPercents, 8);
        }

        message.warn(
          `Превышение распределения на ${formattedPercents}% на сумму ${transformDigitToFinancial(
            /* @ts-ignore */
            dropNonSignificantZeros((+budgetData * (+percentSum - 100)) / 100, 2)
          )}₽`
        );
      } else if (percentSum < 100) {
        let formattedPercents = Number(100 - +percentSum).toString();
        if (formattedPercents.includes("e")) {
          formattedPercents = Number(formattedPercents).toFixed(8);
        } else {
          /* @ts-ignore */
          formattedPercents = dropNonSignificantZeros(formattedPercents, 8);
        }

        message.warn(
          `Не распределено ${formattedPercents}% на сумму ${transformDigitToFinancial(
            /* @ts-ignore */
            dropNonSignificantZeros((+budgetData * (100 - +percentSum)) / 100, 2)
          )}₽`
        );
      } else {
        isPassingValidation = true;
      }

      if (!isPassingValidation) {
        return;
      }
    }

    setIsSaving(true);
    try {
      await deleteSectionClosureDates();
      await updateSectionClosureDates({ planId: planData.id });
      await createSectionClosureDates({ planId: planData.id });
      await deleteSectionExecutors();
      await createSectionExecutors({ planId: planData.id });
      await saveHandler();
    } catch (e) {
    } finally {
      setIsSaving(false);
      setTimeout(() => {
        dataRefetcher();
        dispatch(projectActions.invalidateKey());
      }, 100);
    }
  };

  useEffect(() => {
    /* @ts-ignore */
    const isCompletePercents = closureDates.reduce((acc, curr) => acc + +curr.user_percent, 0) === 100;
    const isCompleteSum =
      closureDates.reduce((acc, curr) => acc + Number(curr.amount?.replaceAll(" ", "")), 0) === +budgetData;
    const oldStratLength = closureDates.filter((el) => el.strategy === 1).length;
    const newStratLength = closureDates.filter((el) => el.strategy === 2).length;
    const isMixed = oldStratLength + newStratLength === closureDates.length;
    setCanSave(() => {
      if (isMixed && closureDates.length && oldStratLength !== 0 && newStratLength !== 0) {
        return true;
      }
      if (closureDates.length && newStratLength) {
        return isCompletePercents || isCompleteSum;
      }
      return true;
    });
  }, [closureDates]);

  return (
    <>
      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b>
        <CalendarRange
          defaultDateStart={dateStart}
          defaultDateEnd={dateEnd}
          classNamePopup={styles.calendarPopup}
          setDefaultDateEnd={setDateEnd}
          setDefaultDateStart={setDateStart}
        />
      </div>
      {!!closureDates && !planData.parent_id && (
        <div className={styles.closure}>
          <ClosureDates
            closureDates={closureDates}
            canEdit
            editClosureDate={editClosureDate}
            removeClosureDate={removeClosureDate}
            addNewClosureDate={addNewClosureDate}
            canSave={canSave} /* @ts-ignore */
            isNew_close_dates={isNew_close_dates}
            budgetData={budgetData}
            validateClosureDates={validateClosureDates}
          />
        </div>
      )}
      {!planData.parent_id && (
        <>
          <div className={cn(styles.pair, styles.centered)}>
            <b>Запланировано выполнение:</b>
            <DoubleInput
              firstValueMeasure={Measure.percent}
              firstValue={plannedPercent?.toString() || ""}
              firstInputSize={1}
              secondValueMeasure={Measure.currency}
              secondValue={plannedAmount?.toString() || ""}
              secondInputSize={2}
              onChangeFirstValue={() => {}}
              onChangeSecondValue={() => {}}
              isFirstDisabled
              isSecondDisabled
              className={isNew_close_dates ? styles.inputBudget : undefined}
              isFullHeight
              variant={"outline"}
            />
          </div>
          {isNew_close_dates && (
            <div className={cn(styles.field, styles.plannedCompletion)}>
              <b>Бюджет (по проекту):</b>
              <InputBase
                className={styles.inputBudget}
                isButtonActive
                value={budgetData}
                valueType={VALUE_TYPES.PRICE}
                /* @ts-ignore */
                numberTransformOptions={{ withCurrencySign: true }}
                disabled
              />
            </div>
          )}
        </>
      )}

      {!!planData.parent_id && (
        <>
          <div className={cn(styles.pair, styles.centered)}>
            <b>Запланировано выполнение:</b>
            <DoubleInput
              firstValueMeasure={Measure.percent}
              firstValue={plannedPercent?.toString() || ""}
              firstInputSize={1}
              secondValueMeasure={Measure.currency}
              secondValue={plannedAmount?.toString() || ""}
              secondInputSize={2}
              onChangeFirstValue={() => {}}
              onChangeSecondValue={() => {}}
              isFirstDisabled
              isSecondDisabled
              className={isNew_close_dates ? styles.inputBudget : undefined}
              isFullHeight
              variant={"outline"}
            />
          </div>
          {isNew_close_dates && (
            <div className={cn(styles.field, styles.plannedCompletion)}>
              <b>Бюджет (по проекту):</b>
              <InputBase
                className={styles.inputBudget}
                isButtonActive
                value={budgetData}
                valueType={VALUE_TYPES.PRICE}
                /* @ts-ignore */
                numberTransformOptions={{ withCurrencySign: true }}
                disabled
              />
            </div>
          )}
          {!!closureDates && (
            /* <Expandable title={`Плановые даты закрытия:`} className={styles.element}> */
            <ClosureDates
              closureDates={closureDates}
              canEdit
              editClosureDate={editClosureDate}
              removeClosureDate={removeClosureDate}
              addNewClosureDate={addNewClosureDate}
              canSave={canSave} /* @ts-ignore */
              isNew_close_dates={isNew_close_dates}
              budgetData={budgetData}
              validateClosureDates={validateClosureDates}
            />
            /* </Expandable> */
          )}
          <Executors
            className={styles.field}
            tableClassName={styles.executorsTable}
            addButtonClassName={styles.addExecutorBtn}
            availablePosts={availablePosts}
            availableRanksByPosts={availableRanksByPosts}
            executors={executors}
            onDelete={onDelete}
            onEdit={onEdit}
            onAddExecutor={onAddExecutor}
            canEdit
          />
        </>
      )}
      <DeleteSectionPlan onClose={onCloseWholeModal} buildingId={objectId} planId={planData.id} />
      <BottomControls isExists isDoubleBtns>
        <ButtonBase secondary onClick={onClose}>
          Отменить
        </ButtonBase>
        <ButtonBase primary onClick={handleSaveClick} isLoading={isSaving}>
          Сохранить
        </ButtonBase>
      </BottomControls>
    </>
  );
};

export default EditSectionPlan;
