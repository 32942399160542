import { useState } from "react";
import { useDispatch } from "react-redux";

import { handBookActions } from "../../model/slice";
import { applyEditingHandbook, cancelEditingHandbook, localDeleteCheckedHandbooks } from "../../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import ConfirmationModal from "entities/ConfirmationModal/ConfirmModal";
import ButtonBase from "shared/ui/controls/ButtonBase";

import styles from "./HandBookEditButton.module.scss";

const HandBookEditButton = () => {
  const dispatch = useDispatch();

  const isEditing = useTypedSelector((state) => state.handBookReducer.isEditing);
  const editings = useTypedSelector((state) => state.handBookReducer.editings);
  const changedCount = Object.values(editings.changes).length;
  const deletedCount = Object.values(editings.deletions).length;
  const hasEditing = changedCount > 0 || deletedCount > 0;
  const hasChecked = Object.values(editings.checked).some((v) => v);

  const [isConfirmApplyOpen, setIsConfirmApplyOpen] = useState(false);

  const enableEditing = () => {
    dispatch(handBookActions.setEditing(true));
  };
  const cancelEditing = () => {
    dispatch(cancelEditingHandbook());
    setIsConfirmApplyOpen(false);
  };
  const applyEditing = () => {
    dispatch(applyEditingHandbook());
    setIsConfirmApplyOpen(false);
  };
  const deleteChecked = () => {
    dispatch(localDeleteCheckedHandbooks());
  };

  if (isEditing) {
    return (
      <fieldset className={styles.editingButtonsFieldset}>
        <legend className={styles.editingButtonsLegend}>Редактирование</legend>
        {hasChecked && (
          <ButtonBase danger onClick={deleteChecked}>
            Удалить выбранные
          </ButtonBase>
        )}
        <ButtonBase secondary onClick={cancelEditing} className={styles.button}>
          Отменить
        </ButtonBase>
        <ButtonBase
          primary
          disabled={!hasEditing}
          onClick={() => setIsConfirmApplyOpen(true)}
          className={styles.button}
        >
          Сохранить
        </ButtonBase>
        <ConfirmationModal
          variant="primary"
          isOpen={isConfirmApplyOpen}
          title="Редактирование справочника"
          action={applyEditing}
          onClose={() => setIsConfirmApplyOpen(false)}
          acceptButtonText="Применить"
        >
          {deletedCount > 0 ? <p>Удалено строк: {deletedCount}</p> : null}
          {changedCount > 0 ? <p>Изменено строк: {changedCount}</p> : null}
          <p>Применить изменения?</p>
        </ConfirmationModal>
      </fieldset>
    );
  }

  return (
    <ButtonBase secondary onClick={enableEditing} className={styles.button}>
      Редактировать
    </ButtonBase>
  );
};

export default HandBookEditButton;
