import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useTypedSelector } from "app/store/typedUseSelector";
import { deletePlanInvalidateKeySelector } from "widgets/DeletePlan/model/selectors";

import { IListResponseData } from "types/ListResponse";
import { IRouterParamsWithObjectId } from "types/routerTypes";

export interface IPlanSection {
  start_at: string;
  end_at: string;
}

export const useSectionPlan = (sectionId: number, isOpen?: boolean) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const [planData, setPlanData] = React.useState<null | IPlanSection>(null);
  const key = useTypedSelector((state) => state.project.invalidateKey);
  const planKey = useSelector(deletePlanInvalidateKeySelector);

  React.useEffect(() => {
    refetchSectionPlan();
  }, [objectId, sectionId, isOpen, key, planKey]);

  const refetchSectionPlan = React.useCallback(() => {
    if (!isOpen) return;
    axios
      .get<IListResponseData<IPlanSection>>(`/building/${objectId}/estimate/sections/plan-intervals/`, {
        params: { section_id: sectionId },
      })
      .then(({ data }) => {
        setPlanData(data.results?.[0] ?? null);
      });
  }, [objectId, sectionId, isOpen]);

  return { planData, refetchSectionPlan };
};
