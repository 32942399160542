import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";

import Select from "components/UI/atoms/Select";
import { handBookActions } from "pages/HandBook/model/slice";
import { createHandbookItem } from "pages/HandBook/model/thunks";

import { IHandbookFormField } from "../HandbookForm/HandbookForm";
import Actions from "shared/ui/controls/Actions/Actions";
import InputBase, { INPUT_BASE_VARIANTS } from "shared/ui/inputs/InputBase";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";

import styles from "./HandbookAddForm.module.scss";

interface IProps {
  fields: IHandbookFormField[];
  colPercents: number[];
  item: { id: number };
}

const HandbookAddForm = ({ fields, colPercents, item }: IProps) => {
  const dispatch = useDispatch();

  const submit = (vals: any) => {
    dispatch(
      createHandbookItem({
        // ID-заглушка в списке добавляемых строк
        id: item.id,
        ...vals,
        type: vals.type || "work",
      })
    );
  };

  const onCancel = () => {
    dispatch(handBookActions.localDeleteAddingItem(item.id));
  };

  const addFormColPercents = [
    colPercents[0] + colPercents[1],
    ...colPercents.slice(2, -1),
    (colPercents.at(-1) || 5) - 5,
    5,
  ];

  return (
    <Form
      onSubmit={submit}
      render={({ form }) => {
        return (
          <div className={styles.root}>
            <RowGrid percents={addFormColPercents} className={styles.row}>
              {fields.map((el) => {
                if (el.type === "text") {
                  return (
                    <Field
                      name={el.name}
                      key={el.name}
                      validate={el.validate}
                      render={({ input, meta }) => (
                        <InputBase
                          input={input}
                          meta={meta}
                          variant={INPUT_BASE_VARIANTS.SECONDARY}
                          classNameInput={styles.textInput} /* label={el.label} */
                        />
                      )}
                    />
                  );
                }
                if (el.type === "select") {
                  return (
                    <Field
                      name={el.name}
                      key={el.name}
                      validate={el.validate}
                      render={({ input, meta }) => <Select input={input} meta={meta} options={el.options ?? []} />}
                    />
                  );
                }
                return null;
              })}
              <Actions canSave canRemove onRemoveDirectly={onCancel} onSave={() => form.submit()} />
            </RowGrid>
          </div>
        );
      }}
    />
  );
};

export default HandbookAddForm;
