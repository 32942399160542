import cn from "classnames";
import moment from "moment";
import React, { useState } from "react";

import { IAosrDoc } from "redux/modules/common/building/journal/aosr/types";

import AosrDocumentAdd from "../AosrDocumentAdd/AosrDocumentAdd";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import FileViewer from "widgets/FileViewer/FileViewer";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import StatusConfirmedIcon from "images/icons/StatusConfirmedIcon";

import styles from "./AosrExistingModal.module.scss";

interface IProps {
  isDisabled?: boolean;
  allDocs?: IAosrDoc[];
  deleteDoc: (doc: IAosrDoc) => void;
  startCreatingDoc: () => void;
}

const AosrDocsTable = ({ isDisabled, allDocs, deleteDoc, startCreatingDoc }: IProps) => {
  if (isDisabled) {
    return (
      <>
        {!!allDocs?.length && (
          <div className={cn(styles.doc, styles.docsHead)}>
            <div>Наименование документа</div>
            <div>Дата</div>
          </div>
        )}
        {allDocs?.map((el, i) => (
          <Row el={el} key={el.id} />
        ))}
      </>
    );
  }

  return (
    <div className={styles.docsContainer}>
      {allDocs?.map((el, i) => (
        <AosrDocumentAdd
          index={i}
          key={i}
          remove={() => {}}
          rowClassName={styles.docsRow}
          isEditMode
          onDirectlyDelete={deleteDoc}
          item={el}
        />
      ))}
      <AddButton onClick={startCreatingDoc} text="Добавить" textPosition="left" className={styles.addButton} />
    </div>
  );
};

export default AosrDocsTable;

interface IRowProps {
  el: IAosrDoc;
}

const Row = ({ el }: IRowProps) => {
  const [fileViewerIndex, setFileViewerIndex] = useState(-1);

  return (
    <div className={styles.doc} key={el.id}>
      <div>{el.title}</div>
      <div>{moment(el.doc_date).format("DD.MM.YYYY")}</div>
      <div className={cn(styles.actionCell, styles.download)}>
        {el.listfiles?.length > 0 && <DownloadIcon onClick={() => setFileViewerIndex(0)} />}
      </div>
      <div className={styles.actionCell}>
        <StatusConfirmedIcon />
      </div>
      <FileViewer
        startIndex={fileViewerIndex}
        isOpen={fileViewerIndex >= 0}
        onClose={() => setFileViewerIndex(-1)}
        files={el.listfiles as any}
      />
    </div>
  );
};
