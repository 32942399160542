import { messageErrorHandler } from "./errorHandler";

export interface IReason {
  response: {
    data: {
      errors: [];
      message?: string;
    };
    status: number;
  };
}

export const errorCatcher = (reason: IReason) => {
  if (reason?.response?.status === 400) {
    if (typeof reason.response.data.message === "string") {
      messageErrorHandler([reason.response.data.message]);
      return;
    }
    messageErrorHandler(reason?.response?.data?.errors);
  } else {
    if (reason?.response?.status === 404) {
      //messageErrorHandler(reason?.response?.data?.errors);
    } else {
      console.log(reason);
    }
  }
};
