import React, { useState } from "react";

import AddOneExpenditure from "./AddOneExpenditure";
import AddButton from "shared/ui/controls/AddButton/AddButton";

import styles from "./AddExpenditure.module.scss";

interface IProps {
  buildingId: number;
  sectionId: number;
  isOutOfEstimate: boolean;
  addedExpenditures: number[];
  setAddedExpenditures: React.Dispatch<React.SetStateAction<number[]>>;
  isBtnHidden?: boolean;
  isWithNumberVariant?: boolean;
}

const AddExpenditures: React.FC<IProps> = ({
  buildingId,
  sectionId,
  isOutOfEstimate,
  addedExpenditures,
  setAddedExpenditures,
  isBtnHidden,
  isWithNumberVariant = true,
}) => {
  const addExpenditure = () => {
    setAddedExpenditures((prev) => [...prev, Date.now()]);
  };

  const deleteExpenditure = (id: number) => {
    setAddedExpenditures((prev) => prev.filter((el) => +el !== +id));
  };

  return (
    <div className={styles.container}>
      {addedExpenditures?.map((el) => (
        <AddOneExpenditure
          key={el}
          buildingId={buildingId}
          sectionId={sectionId}
          isOutOfEstimate={isOutOfEstimate}
          tempId={el}
          onDelete={deleteExpenditure}
          isWithNumberVariant={isWithNumberVariant}
        />
      ))}
      {!isBtnHidden && (
        <AddButton variant="solid" text="Добавить работу" textPosition="left" className={styles.addBtn} onClick={addExpenditure} />
      )}
    </div>
  );
};

export default AddExpenditures;
