import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { handBookActions } from "pages/HandBook/model/slice";

import ImportModal from "./ui/ImportModal/ImportModal";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { errorCatcher } from "utils/helpers/errorCatcher";

import { ReactComponent as ImportDocumentationIcon } from "shared/assets/icons/importDocumentation.svg";

import styles from "./HandBookImport.module.scss";

interface IProps {}

type Tab = "works" | "resources" | "mims";

const HandBookImport = ({}: IProps) => {
  const { tab } = useParams<{ tab: Tab }>();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [countOfPositions, setCountOfPositions] = useState(0);
  const [errList, setErrList] = useState({});
  const [isErr, setIsErr] = useState(false);

  const onDownloadSample = () => {
    const sampleSuffix = tab === "works" ? routeSuffixMap["works"] : routeSuffixMap["resources"];
    axios
      .post(`/help/manual_expenditures/files/${sampleSuffix}/template/`, {}, { responseType: "blob" })
      .then((response) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers.contentType }));
        link.download = formatFileName(response.headers["content-disposition"]);
        link.click();
      })
      .catch(errorCatcher);
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    setIsErr(false);
    const file: any = event.target.files?.item(0);
    getArrayFilesInBase64([file]).then((arrayFilesInBase64) => {
      axios
        .post(`/help/manual_expenditures/files/${routeSuffixMap[tab]}/`, {
          name: arrayFilesInBase64[0].name,
          file: arrayFilesInBase64[0].file,
        })
        .then((res) => {
          setCountOfPositions(res.data.created);
          dispatch(handBookActions.invalidateKey());
          setIsLoading(false);
          setIsSuccess(true);
        })
        .catch((err) => {
          setIsLoading(false);
          if (!err.response.data.detail) {
            err.response.data?.errors && setErrList(err.response.data?.errors);
            setIsErr(true);
          }
          if (typeof err.response?.data?.error === "string") {
            message.error(err.response?.data?.error);
          }
          errorCatcher(err);
        });
    });
  };

  const onSuccess = () => {
    setIsOpen(false);
    setIsSuccess(false);
    setCountOfPositions(0);
    setIsErr(false);
    setErrList({});
  };

  return (
    <>
      <button className={styles.importButton} onClick={() => setIsOpen(true)}>
        <ImportDocumentationIcon />
        <span>Импорт</span>
      </button>
      <ImportModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setIsSuccess(false);
          setCountOfPositions(0);
          setIsErr(false);
          setErrList({});
        }}
        isErr={isErr}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onDownloadSample={onDownloadSample}
        onChangeInput={onChangeInput}
        onSuccess={onSuccess}
        errList={errList}
        countOfPositions={countOfPositions}
      />
    </>
  );
};

export default HandBookImport;

const formatFileName = (name: string) => {
  return name.split("filename=")[1];
};

const routeSuffixMap: Record<Tab, string> = {
  works: "works",
  mims: "mim",
  resources: "resources",
};
