import { CHART_TABS, ChartActionsType, IChartFilters, IChartTree } from "redux/modules/common/chart/types";

import { CHART_TREE_LVL } from "components/pages/Chart/constants";

import { stringToFilterable } from "./stringToFilterable";

export const checkSatisfyFilters = (
  tree: IChartTree,
  { filters, actions, tab }: { filters: IChartFilters; actions: ChartActionsType; tab: CHART_TABS | null }
) => {
  let satisfiesFilteringActions = true;
  const hasFilteringActions =
    actions.hide_expenditures_without_plans ||
    actions.hide_expenditures_without_facts ||
    actions.hide_expenditures_without_remarks;
  if (actions.hide_expenditures_without_plans) {
    if (tab === CHART_TABS.WORK) {
      if (tree.lvl === CHART_TREE_LVL.EXPENDITURE) {
        satisfiesFilteringActions &&= Boolean(tree.processedIntervals?.plan_works?.length);
      } else if (tree.lvl > CHART_TREE_LVL.PROJECT) {
        satisfiesFilteringActions &&= Boolean(tree.bubbledIntervals?.plan_works?.length);
      }
    } else if (
      tab === CHART_TABS.RESOURCES ||
      tab === CHART_TABS.EQUIPMENT ||
      tab === CHART_TABS.MATERIALS ||
      tab === CHART_TABS.MIM
    ) {
      if (tree.lvl === CHART_TREE_LVL.EXPENDITURE) {
        satisfiesFilteringActions &&= Boolean(tree.processedIntervals?.plan_resources?.length);
      } else if (tree.lvl > CHART_TREE_LVL.PROJECT) {
        satisfiesFilteringActions &&= Boolean(tree.bubbledIntervals?.plan_resources?.length);
      }
    }
  }
  if (actions.hide_expenditures_without_facts) {
    if (tab === CHART_TABS.WORK) {
      if (tree.lvl === CHART_TREE_LVL.EXPENDITURE) {
        satisfiesFilteringActions &&= Boolean(tree.processedIntervals?.fact_works?.length);
      } else if (tree.lvl > CHART_TREE_LVL.PROJECT) {
        satisfiesFilteringActions &&= Boolean(tree.bubbledIntervals?.fact_works?.length);
      }
    } else if (
      tab === CHART_TABS.RESOURCES ||
      tab === CHART_TABS.EQUIPMENT ||
      tab === CHART_TABS.MATERIALS ||
      tab === CHART_TABS.MIM
    ) {
      if (tree.lvl === CHART_TREE_LVL.EXPENDITURE) {
        satisfiesFilteringActions &&= Boolean(tree.processedIntervals?.fact_resources?.length);
      } else if (tree.lvl > CHART_TREE_LVL.PROJECT) {
        satisfiesFilteringActions &&= Boolean(tree.bubbledIntervals?.fact_resources?.length);
      }
    }
  }
  if (actions.hide_expenditures_without_remarks) {
    if (tab === CHART_TABS.WORK) {
      if (tree.lvl === CHART_TREE_LVL.EXPENDITURE) {
        satisfiesFilteringActions &&= Boolean(
          tree.processedIntervals?.fact_works?.some(
            (work) => work.remarks_in_work?.length > 0 || work.ticket_remarks_in_work?.length > 0
          )
        );
      } else if (tree.lvl > CHART_TREE_LVL.PROJECT) {
        satisfiesFilteringActions &&= Boolean(
          tree.bubbledIntervals?.fact_works?.some(
            (work) => work.remarks_in_work?.length > 0 || work.ticket_remarks_in_work?.length > 0
          )
        );
      }
    }
  }
  if (!satisfiesFilteringActions) return false;

  if (!hasFilteringActions && filters.skipBranchLvl && filters.skipBranchLvl[tree.lvl]) {
    return false;
  }

  if (
    filters.searchText &&
    !stringToFilterable(tree.name || "").includes(filters.searchText) &&
    !stringToFilterable(tree.number?.toString() || "").includes(filters.searchText)
  ) {
    return false;
  }
  if (filters.dateStart) {
    if (!tree.plan_start || +new Date(tree.plan_start || "") < +new Date(filters.dateStart)) {
      return false;
    }
  }
  if (filters.dateEnd) {
    if (!tree.plan_end || +new Date(tree.plan_end || "") > +new Date(filters.dateEnd)) {
      return false;
    }
  }
  return true;
};
