import { ChartActions, ChartActionsType, ChartFilterType, IChartFilters } from "redux/modules/common/chart/types";

const SYSTEM_FILTERS: Partial<Record<ChartFilterType, boolean>> = {
  skipBranchLvl: true,
};

const ACTIONS_AS_FILTERS: ChartActionsType = {
  [ChartActions.hide_expenditures_without_plans]: true,
  [ChartActions.hide_expenditures_without_facts]: true,
  [ChartActions.hide_expenditures_without_remarks]: true,
};

export const checkHasFilters = (filters: IChartFilters) => {
  return (
    filters && Object.entries(filters).some(([filter, value]) => !SYSTEM_FILTERS[filter as ChartFilterType] && value)
  );
};

export const checkHasFilteringActions = (actions: ChartActionsType) => {
  return (
    actions && Object.entries(actions).some(([action, value]) => ACTIONS_AS_FILTERS[action as ChartActions] && value)
  );
};
