
export default function TwoHorizontalSmallLinesIcon({color = '#4FB2ED', width = "28", className = ''}) {
  return (
    <svg
      width={width}
      height="10"
      viewBox="0 0 28 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.9021 10.0003C12.5208 10.0003 12.155 9.84882 11.8853 9.57914C11.6156 9.30946 11.4641 8.9437 11.4641 8.56232C11.4641 8.18094 11.6156 7.81518 11.8853 7.5455C12.155 7.27582 12.5208 7.12432 12.9021 7.12432H26.1441C26.5255 7.12432 26.8913 7.27582 27.161 7.5455C27.4306 7.81518 27.5821 8.18094 27.5821 8.56232C27.5821 8.9437 27.4306 9.30946 27.161 9.57914C26.8913 9.84882 26.5255 10.0003 26.1441 10.0003H12.9021ZM1.65314 2.96932C1.46816 2.96945 1.28496 2.93313 1.11402 2.86243C0.943073 2.79173 0.787739 2.68804 0.656888 2.55728C0.526037 2.42652 0.422235 2.27126 0.351414 2.10037C0.280593 1.92948 0.244141 1.74631 0.244141 1.56132C0.244141 1.37634 0.280593 1.19316 0.351414 1.02227C0.422235 0.85138 0.526037 0.696119 0.656888 0.565361C0.787739 0.434603 0.943073 0.330911 1.11402 0.260211C1.28496 0.189512 1.46816 0.153189 1.65314 0.153321H20.3981C20.7716 0.153321 21.1297 0.301663 21.3937 0.565714C21.6578 0.829765 21.8061 1.1879 21.8061 1.56132C21.8061 1.93475 21.6578 2.29288 21.3937 2.55693C21.1297 2.82098 20.7716 2.96932 20.3981 2.96932H1.65314Z"
        fill={color}
      />
    </svg>
  )
}
