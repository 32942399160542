import { message } from "antd";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { userSelector } from "redux/modules/_TODO/auth";

import AddPlanMimsInModal from "../../../../../../UI/_TODO/AddingListInModal/AddPlanMimsInModal";
import { useSelectingMims } from "../../../../../../UI/_TODO/AddingListInModal/hooks/useSelectingMims";
import AddPlanMaterialsInModal from "components/UI/_TODO/AddingListInModal/AddPlanMaterialsInModal";
import AddWorkersInModal from "components/UI/_TODO/AddingListInModal/AddWorkersInModal";
import { useSelectingMaterials } from "components/UI/_TODO/AddingListInModal/hooks/useSelectingMaterials";
import { useSelectingWorkers } from "components/UI/_TODO/AddingListInModal/hooks/useSelectingWorkers";
import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import DayWorkerAndEvent from "components/UI/_TODO/WorkOrMaterialsModals/components/DayWorkerAndEvent/DayWorkerAndEvent";
import InputNumber from "components/UI/atoms/InputNumber";

import { usePlanEdit } from "./usePlanEdit";
import Button from "shared/ui/controls/ButtonBase";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import DateSelectOrPicker from "shared/ui/inputs/DateSelectOrPicker/DateSelectOrPicker";
import ExpandableFilesInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import EditAxesAndMarks from "widgets/AxesAndMarks/widgets/EditAxesAndMarks/EditAxesAndMarks";
import DeletePlan from "widgets/DeletePlan/DeletePlan";

import { COUNT_MAX_DECIMALS_PLANS_FACTS_EDITING } from "constants/forms";
import { IMaterialInPlanInterval } from "types/interfaces/Materials";
import { IMimInPlanInterval } from "types/interfaces/Mims";
import { IEmployee, personFullNameWithId } from "types/personsTypes";

import styles from "./PlanEditing.module.scss";

interface Iprops {
  planCreatingDate?: string;
  planCreator?: personFullNameWithId;
  productId: number;
  planDateStart: string;
  planDateEnd: string;
  planCount: number;
  defaultMaterials: IMaterialInPlanInterval[];
  defaultMims: IMimInPlanInterval[];
  defaultWorkers: IEmployee[];
  onClose: () => void;
  buildingId: number;
  intervalId: number;
  files?: unknown[];
  onUploadFiles?: (file: File) => void;
  onDeleteFiles?: (id: number) => void;
  defaultWorkersCounts?: { [key: string]: number };
  onEditCallback?: () => void;
  isExpendituresGroup?: boolean;
  getUpdatedList?: (intervalId: string) => void;
  sectionId: number;
  paymentDate: string;
  closeWholeModal?: () => void;
  expemdituresIds: number[];
  measure?: string;
}

function PlanEditing({
  planCreatingDate,
  planCreator,
  productId,
  planDateEnd,
  planDateStart,
  planCount,
  defaultMaterials,
  defaultMims,
  defaultWorkers,
  onClose,
  buildingId,
  intervalId,
  files,
  onDeleteFiles,
  onUploadFiles,
  defaultWorkersCounts,
  onEditCallback,
  isExpendituresGroup,
  getUpdatedList,
  sectionId,
  paymentDate,
  closeWholeModal,
  expemdituresIds,
  measure,
}: Iprops) {
  const {
    confirmHandler,
    cancelHandler,
    changeCountHandler,
    validateSubmitting,
    deleteAddedHandler,
    selectHandler,
    addedUsers,
    selectedUsers,
    selectedUsersIds,
    addedUsersIds,
    clearWorkers,
  } = useSelectingWorkers(defaultWorkers, defaultWorkersCounts, isExpendituresGroup);

  const {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler: deleteAddedMaterial,
    selectMaterialsHandler,
    addedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    clearMaterials,
  } = useSelectingMaterials(defaultMaterials, true, isExpendituresGroup);

  const formattedAddedMatrrials = useMemo(() => {
    return addedMaterials?.map((el) => {
      const matchedMaterial = defaultMaterials?.find((mat) => mat.material?.id === el.id);
      const maximumCount = matchedMaterial?.material?.estimate_expenditure?.estimate_count;
      return {
        ...el,
        available_count: !!maximumCount ? maximumCount : el.available_count,
      };
    });
  }, [addedMaterials, defaultMaterials]);

  const {
    confirmMimsHandler,
    cancelMimsHandler,
    changeCountMimsHandler,
    validateSubmittingMims,
    deleteAddedHandler: deleteAddedMims,
    selectMimsHandler,
    addedMims,
    addedMimsIds,
    selectedMims,
    selectedMimsIds,
    clearMims,
  } = useSelectingMims(defaultMims, true, isExpendituresGroup);

  const [isAxesDirty, setIsAxesDirty] = useState(false);

  const user = useSelector(userSelector);

  const isUserPlanCreator = planCreator?.id === user?.id;

  const {
    count,
    setCount,
    dateEnd,
    dateStart,
    setDateEnd,
    setDateStart,
    submitHandler,
    closureDatesOptions,
    selectedClosureDateId,
    setSelectedClosureDateId,
    planPaymentDate,
    setPlanPaymentDate,
  } = usePlanEdit({
    planCount,
    planDateEnd,
    planDateStart,
    addedUsers,
    addedMaterials,
    addedMims,
    expenditureId: productId,
    buildingId,
    intervalId,
    onClose,
    previousMaterials: defaultMaterials,
    previousMims: defaultMims,
    onEditCallback,
    isExpendituresGroup,
    getUpdatedList,
    sectionId,
    paymentDate,
    closeWholeModal,
  });

  const onSubmit = () => {
    if (isAxesDirty) {
      message.error("Сначала примените изменения в документации");
      return;
    }
    validateSubmittingMaterials() && validateSubmittingMims() && validateSubmitting() && submitHandler();
  };
  const [dynamicsData, setDynamicsData] = useState({});

  useEffect(() => {
    axios
      .get(`/building/${buildingId}/interval_indicators/${isExpendituresGroup ? "group" : "expenditure"}/${productId}/`)
      .then((data) => {
        setDynamicsData(data.data);
      });
  }, []);

  return (
    <>
      <div className={styles.container}>
        <DayWorkerAndEvent date={planCreatingDate!} author={planCreator!} label="Создал:" />
        <div className={styles.content}>
          <div className={styles.hiddenWrapper}>
            <div className={styles.dinamics}>
              <div className={styles.label}>Количество: {measure && `(${measure})`}</div>
              <div className={styles.dinamicsContainer}>
                <div>
                  <span>По смете:</span>
                  {/* @ts-ignore */}
                  <p>{dynamicsData.estimate}</p>
                </div>
                <div>
                  <span>К сдаче:</span>
                  {/* @ts-ignore */}
                  <p>{dynamicsData.todo}</p>
                </div>
                <div>
                  <span>Сдано:</span>
                  {/* @ts-ignore */}
                  <p>{dynamicsData.completed}</p>
                </div>
                <div>
                  <span>Остаток:</span>
                  {/* @ts-ignore */}
                  <p>{dynamicsData.left}</p>
                </div>
              </div>
            </div>
            <div className={styles.containerPlan}>
              <div className={styles.leftSide}>
                <div className={styles.label}>Количество</div>
                <div className={styles.fieldValue}>
                  <InputNumber
                    value={count as any}
                    setValue={setCount as any}
                    decimalPlace={COUNT_MAX_DECIMALS_PLANS_FACTS_EDITING}
                  />
                </div>
              </div>
              <div className={styles.rightSide}>
                <span>Спланировано:</span>
                <div>
                  {/* @ts-ignore */}
                  <p className={styles.planText}>{dynamicsData.planned}</p>
                </div>
              </div>
            </div>
            <div className={styles.pair}>
              <div className={styles.pr1}>
                <div className={styles.label}>Укажите период </div>
                <div className={styles.fieldRow}>
                  <div className={styles.fieldCalendar}>
                    {/* @ts-ignore */}
                    <CalendarRange
                      defaultDateStart={dateStart}
                      setDefaultDateStart={setDateStart}
                      defaultDateEnd={dateEnd}
                      setDefaultDateEnd={setDateEnd}
                      classNamePopup={styles.rightCalendar}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.label}>Плановая дата закрытия </div>
                <div className={styles.fieldRow}>
                  <DateSelectOrPicker
                    options={closureDatesOptions}
                    onPickerChange={(date) => {
                      setSelectedClosureDateId(null); /* @ts-ignore */
                      setPlanPaymentDate(date);
                    }}
                    onSelectChange={(id) => {
                      setSelectedClosureDateId(id as number);
                    }}
                    selectValue={selectedClosureDateId!}
                    pickerValue={planPaymentDate}
                  />
                </div>
              </div>
            </div>

            <AddWorkersInModal
              addedUsers={addedUsers}
              selectedUsers={selectedUsers}
              onAccept={confirmHandler}
              onDecline={cancelHandler}
              onChangeCount={changeCountHandler}
              onSelect={selectHandler}
              selectedIds={selectedUsersIds}
              onDelete={deleteAddedHandler}
              addedIds={addedUsersIds}
            />
            <AddPlanMaterialsInModal
              addedMaterials={formattedAddedMatrrials}
              selectedMaterials={selectedMaterials}
              onAccept={confirmMaterialsHandler}
              onDecline={cancelMaterialsHandler}
              onChangeCount={changeCountMaterialsHandler}
              onSelect={selectMaterialsHandler}
              selectedIds={selectedMaterialsIds}
              onDelete={deleteAddedMaterial}
              addedIds={addedMaterialsIds}
              expenditureId={productId}
              wholeWorkCount={count}
            />
            <AddPlanMimsInModal
              addedMims={addedMims}
              selectedMims={selectedMims}
              onAccept={confirmMimsHandler}
              onDecline={cancelMimsHandler}
              onChangeCount={changeCountMimsHandler}
              onSelect={selectMimsHandler}
              selectedIds={selectedMimsIds}
              onDelete={deleteAddedMims}
              addedIds={addedMimsIds}
              expenditureId={productId}
              wholeWorkCount={count} /* @ts-ignore */
              defaultMimsList={defaultMims}
            />
            <EditAxesAndMarks
              expenditureIds={isExpendituresGroup ? expemdituresIds : [productId]}
              id={intervalId}
              planOrFact="plan"
              workOrGroup={isExpendituresGroup ? "group" : "work"}
            />
            {!isExpendituresGroup && files && (
              <ExpandableFilesInput
                files={files}
                canExtractFiles={false}
                onRemoveFileDirectly={onDeleteFiles}
                onUploadFileDirectly={onUploadFiles}
              />
            )}
            {isUserPlanCreator ? (
              <DeletePlan
                buildingId={String(buildingId)}
                planId={intervalId}
                expenditureId={productId}
                onClose={() => {
                  closeWholeModal?.();
                  onEditCallback?.();
                }}
                isGroup={!!isExpendituresGroup}
              />
            ) : null}
          </div>
        </div>
        <BottomControls isExists>
          <Button medium secondary disabled={false} onClick={onClose}>
            Отменить
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button medium primary onClick={onSubmit} disabled={!count}>
            Сохранить
          </Button>
        </BottomControls>
      </div>
    </>
  );
}

export default PlanEditing;
