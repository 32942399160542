import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import HandBookAddButton from "pages/HandBook/features/HandBookAddButton/HandBookAddButton";
import { getHandbooks, getHandbooksMore } from "pages/HandBook/model/thunks";
import { IHandBook, IHandBookRequest } from "pages/HandBook/model/types";
import { IHandbookFormField } from "pages/HandBook/ui/HandbookForm/HandbookForm";
import HandbookRow from "pages/HandBook/ui/HandbookRow/HandbookRow";
import { handbooksModifyWithEditings } from "pages/HandBook/utils/handbooksModifyWithEditings";

import { useTypedSelector } from "app/store/typedUseSelector";
import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import ListWrapper from "shared/ui/layout/ListWrapper/ListWrapper";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";
import icon from "./../../assets/handbook.svg";

import { useCheckboxesToggleHold } from "hooks/useCheckboxesToggleHold";

import { generateStorageKey } from "utils/helpers/generateStorageKey";
import { handBookActions } from "pages/HandBook/model/slice";

interface IProps {
  defaultFilter?: IHandBookRequest;
  colPercents: number[];
  columns: IHandbookColumn[];
  formColPercents?: number[];
  fields: IHandbookFormField[];
}

export interface IHandbookColumn {
  header: {
    title: string;
    filterName?: string;
    filterType?: "select";
    filterOptions?: any[];
  };
  isCentered?: boolean;
  isNoBreak?: boolean;
  isLast?: boolean;
  isRighted?: boolean;
  render: (item: IHandBook) => ReactNode;
}

const HandBookWorks = ({ defaultFilter = {}, colPercents, columns, formColPercents, fields}: IProps) => {
  const [filter, setFilter] = useState<IHandBookRequest>(defaultFilter);
  const dispatch = useDispatch();
  const key = generateStorageKey(filter as any);
  const handbooks = useTypedSelector((state) => state.handBookReducer.handbooks)[key];
  const isLoading = useTypedSelector((state) => state.handBookReducer.loadings)[key];
  const invalidationKey = useTypedSelector((state) => state.handBookReducer.invalidationKey);
  const editings = useTypedSelector((state) => state.handBookReducer.editings);

  const displayHandbooks = useMemo(() => {
    if (!handbooks?.results) return handbooks?.results;
    return handbooksModifyWithEditings(handbooks.results, editings);
  }, [handbooks?.results, editings]);

  useEffect(() => {
    dispatch(getHandbooks({ ...filter, limit: 100 }));
  }, [invalidationKey, filter]);

  useEffect(() => {
    return () => {
      dispatch(handBookActions.clearLocalAddings());
    }
  }, []);

  useCheckboxesToggleHold();

  return (
    <>
      <ListWrapper
        filter={filter}
        onPagination={() => {
          dispatch(getHandbooksMore({ ...filter, offset: handbooks?.results?.length }));
        }}
        total={handbooks?.count}
        header={
          <TableReusableHead isSticky isNoDefaultGridCols>
            <RowGrid percents={colPercents}>
              {columns.map((el) => (
                <TableReusableHeaderCell
                  key={el.header.title}
                  isCentered={el.isCentered}
                  filterType={el.header.filterType ? el.header.filterType : el.header.filterName ? "text" : undefined}
                  filterField={el.header.filterName}
                  onChangeFilter={(name, value) => setFilter((prev) => ({ ...defaultFilter, ...prev, [name]: value }))}
                  filterTitle={el.header.title}
                  isLast={el.isLast}
                  filterOptions={el.header.filterOptions}
                  isRighted={el.isRighted}
                >
                  {el.header.title}
                </TableReusableHeaderCell>
              ))}
            </RowGrid>
          </TableReusableHead>
        }
        data={displayHandbooks}
        dataMapper={(item) => (
          <HandbookRow
            key={item.id}
            item={item}
            colPercents={colPercents}
            columns={columns}
            formColPercents={formColPercents}
            fields={fields}
          />
        )}
        isLoading={isLoading}
        placeholderProps={{ img: icon }}
      />
      <HandBookAddButton />
    </>
  );
};

export default HandBookWorks;
