import axios from "axios";

import { IHandBook, IHandBookRequest } from "./types";
import { IListResponseData } from "types/ListResponse";

export const handBookApi = {
  getList: (filter: IHandBookRequest = {}) => {
    const params = new URLSearchParams();
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((e) => params.append(key, e));
      } else {
        params.append(key, value);
      }
    });
    return axios.get<IListResponseData<IHandBook>>(`/help/manual_expenditures/`, {
      params,
    });
  },
  post: (data: any) => {
    return axios.post<IHandBook>(`/help/manual_expenditures/`, data);
  },
  patch: (id: number, data: any) => {
    return axios.patch<IHandBook>(`/help/manual_expenditures/${id}/`, data);
  },
  delete: (id: number) => {
    return axios.delete(`/help/manual_expenditures/${id}/`);
  },
};
