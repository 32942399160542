import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import { chartViewModeSelector } from "redux/modules/common/chart/selectors";
import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

import { ITab } from "shared/ui/controls/TabBar/TabBarNotLinks";

import { useUrlModule } from "utils/hooks/useUrlModule";

export const useChartViewModeTabs = () => {
  const dispatch = useDispatch();
  const chartViewMode = useSelector(chartViewModeSelector);
  const module = useUrlModule();

  const setChartViewMode = useCallback(
    (mode: CHART_VIEW_MODE | string) => {
      dispatch(chartActions.setViewMode(mode as CHART_VIEW_MODE));
    },
    [chartViewMode]
  );

  const chartViewModeTabs: ITab[] = [
    {
      id: CHART_VIEW_MODE.DAYS_30,
      text: "Дни | 30",
    },
    {
      id: CHART_VIEW_MODE.DAYS_15,
      text: "Дни | 15",
    },
    module === "objects" && { id: CHART_VIEW_MODE.WEEKS, text: "Недели" },
    { id: CHART_VIEW_MODE.MONTHS, text: "Месяцы" },
  ].filter((x) => Boolean(x)) as ITab[];

  useEffect(() => {
    if (!chartViewModeTabs.length || chartViewModeTabs.findIndex((t) => t.id === chartViewMode) !== -1) return;
    setChartViewMode(chartViewModeTabs[0].id);
  }, [chartViewModeTabs, chartViewMode]);

  return {
    chartViewModeTabs,
    chartViewMode,
    setChartViewMode,
  };
};
