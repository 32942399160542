import axios from "axios";
import React, { useState } from "react";

import { useMaterialsInvalidationKeyContext } from "components/pages/Materials/common/_MaterialsInvalidationKeyContext/_MaterialsInvalidationKeyContext";

import { useConfirmModal } from "entities/ConfirmationModal/ConfirmationContext";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { errorCatcher } from "utils/helpers/errorCatcher";

interface IProps {
  expenditure: any;
  objectId: string;
  onClose: () => void;
}

const SpecificationDeleteBindingBtn = ({ expenditure, objectId, onClose }: IProps) => {
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();
  const [isPending, setIsPending] = useState(false);
  const { updateInvalidationKey } = useMaterialsInvalidationKeyContext();

  const onClick = () => {
    openConfirmModal({
      isOpen: true,
      onClose: closeConfirmModal,
      action: () => {
        closeConfirmModal();
        setIsPending(true);
        axios
          .delete(`/building/${objectId}/materials/specification/${expenditure.id}/delete_related_work`)
          .then(() => {
            updateInvalidationKey();
            onClose();
          })
          .catch(errorCatcher)
          .finally(() => {
            setIsPending(false);
          });
      },
      acceptButtonText: "Удалить связь",
      variant: "secondary",
      children: `У ресурса есть привязка к работе ${expenditure.estimate_expenditure.name}`,
    });
  };

  return (
    <ButtonBase danger onClick={onClick} isLoading={isPending}>
      Удалить связь
    </ButtonBase>
  );
};

export default SpecificationDeleteBindingBtn;
