import { IHandBook } from "pages/HandBook/model/types";
import { IHandbookColumn } from "pages/HandBook/widgets/HandBookWorks/HandBookWorks";

import HandbookAddForm from "../HandbookAddForm/HandbookAddForm";
import HandbookForm, { IHandbookFormField } from "../HandbookForm/HandbookForm";
import { useTypedSelector } from "app/store/typedUseSelector";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";

import styles from "./HandbookRow.module.scss";

interface IProps {
  colPercents: number[];
  columns: IHandbookColumn[];
  item: IHandBook;
  formColPercents?: number[];
  fields: IHandbookFormField[];
}

const HandbookRow = ({ colPercents, columns, item, formColPercents, fields }: IProps) => {
  const isEditing = useTypedSelector((state) => state.handBookReducer.isEditing);
  // @ts-ignore
  if (item._new) {
    if (isEditing) return null;
    return <HandbookAddForm item={item} colPercents={formColPercents ?? colPercents} fields={fields} />;
  }
  if (isEditing) {
    return <HandbookForm item={item} colPercents={formColPercents ?? colPercents} fields={fields} />;
  }

  return (
    <TableReusableRow isHoverable={false} className={styles.row} containerClassName={styles.container}>
      <RowGrid percents={colPercents}>
        {columns.map((el) => (
          <TableReusableCell isCentered={el.isCentered} isNoBreak={el.isNoBreak} isRighted={el.isRighted}>
            {el.render(item)}
          </TableReusableCell>
        ))}
      </RowGrid>
    </TableReusableRow>
  );
};

export default HandbookRow;
