import cn from "classnames";
import React, { SetStateAction } from "react";

import SearchFilterSum from "../../../../components/UI/atoms/_TODO/SearchFilterSum";
import SearchRangeDateFilter from "../../../../components/UI/atoms/_TODO/SearchRangeDateFilter";
import SearchSelectFilter from "../../../../components/UI/atoms/_TODO/SearchSelectFilter";

import TableFilterTextInput from "shared/ui/inputs/TableFilterTextInput/TableFilterTextInput";

import styles from "./TableReusableHead.module.scss";

type cellPropsType = {
  children?: React.ReactNode;
  className?: string;
  isCentered?: boolean;
  filterType?: "text" | "select" | "datesRange" | "sum";
  onChangeFilter?: (name: string, value: string) => void;
  filterTitle?: string;
  filterOptions?: any[];
  filterField?: string;
  rangeStartField?: string;
  rangeEndField?: string;
  isRighted?: boolean;
  isLast?: boolean;
  filterSelectDefaultValue?: any;
  setFilter?: React.Dispatch<SetStateAction<{}>>;
};

function Cell({
  children,
  className,
  isCentered,
  filterOptions,
  filterTitle,
  filterType,
  onChangeFilter,
  filterField,
  rangeEndField,
  rangeStartField,
  isRighted,
  isLast,
  filterSelectDefaultValue,
  setFilter,
}: cellPropsType) {
  return (
    <div className={cn(styles.headerCell, className, { [styles.centered]: isCentered, [styles.righted]: isRighted })}>
      {children}
      {filterType === "text" && (
        <TableFilterTextInput
          title={filterTitle ?? (children as string)}
          setFilter={(value) => onChangeFilter?.(filterField!, value)}
          last={isLast}
        />
      )}
      {filterType === "datesRange" && (
        <SearchRangeDateFilter
          last={isLast}
          title={filterTitle ?? (children as string)}
          setFilterEnd={(value: string) => onChangeFilter?.(rangeEndField!, value)}
          setFilterStart={(value: string) => onChangeFilter?.(rangeStartField!, value)}
          setBothFilters={(start: string, end: string) => {
            onChangeFilter?.(rangeStartField!, start);
            onChangeFilter?.(rangeEndField!, end);
            setFilter?.((prev) => {
              return {
                ...prev,
                [rangeStartField!]: start,
                [rangeEndField!]: end,
              };
            });
          }}
        />
      )}
      {filterType === "select" && (
        <SearchSelectFilter
          title={filterTitle ?? (children as string)}
          setFilter={(value: string) => onChangeFilter?.(filterField!, value)}
          filterName={filterOptions}
          last={isLast}
          defaultValue={filterSelectDefaultValue}
        />
      )}
      {filterType === "sum" && (
        <SearchFilterSum
          last={isLast}
          title={filterTitle ?? (children as string)}
          setFilterStart={(value: string) => {
            onChangeFilter?.(rangeStartField!, value);
          }}
          setFilterEnd={(value: string) => {
            onChangeFilter?.(rangeEndField!, value);
          }}
        />
      )}
    </div>
  );
}

export const TableReusableHeaderCell = React.memo(Cell);

type propsType = {
  className?: string;
  children?: React.ReactNode;
  isWithEar?: boolean;
  isSticky?: boolean;
  dataTestId?: string;
  isSmall?: boolean;
  isNoDefaultGridCols?: boolean;
};

function TableReusableHead({
  className,
  children,
  isSticky,
  isWithEar,
  dataTestId,
  isSmall,
  isNoDefaultGridCols,
}: propsType) {
  return (
    <div
      className={cn(styles.tableHeader, className, {
        [styles.sticky]: isSticky,
        [styles.withEar]: isWithEar,
        [styles.small]: isSmall,
        [styles.isNoDefaultGridCols]: isNoDefaultGridCols,
      })}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
}

export default React.memo(TableReusableHead);
