import { message } from "antd";
import React, { useCallback, useMemo, useState } from "react";

import { IUploadedFile } from "components/pages/Tasks/components/TaskFiles/TaskFilesPopup/TaskFilesPopup";

import ConfirmationModal from "../../../../entities/ConfirmationModal/ConfirmModal";
import FileRow from "../FileRow/FileRow";
import FileViewer from "widgets/FileViewer/FileViewer";

import { IFile } from "types/interfaces/Files";

import downloadFile from "../../../../utils/helpers/download";
import { serializeFilesByName } from "utils/formatters/serializeFilesByName";

interface IProps {
  files: IFile[] | IUploadedFile[];
  removeFile: (removedFileId: number) => void;
  maxDisplayingCount?: number;
  mustConfirmDownload?: boolean;
  isDisabled?: boolean;
  canExport?: boolean;
  _TODO_onDirectlyClick?: (index: number) => void;
  canRemove?: boolean;
  isFileViewer?: boolean;
}

const FilesList: React.FC<IProps> = ({
  files,
  removeFile,
  isDisabled,
  maxDisplayingCount,
  mustConfirmDownload,
  canExport = true,
  _TODO_onDirectlyClick,
  canRemove = true,
  isFileViewer,
}) => {
  const [filesLoading, setFilesLoading] = useState<Record<number, boolean>>({});

  const [downloadedFile, setDownloadedFile] = useState<IFile | null>(null);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const closeConfirmationModal = useCallback(() => setIsOpenConfirmationModal(false), []);

  const handleDownloadFile = useCallback(async (file: IFile | null) => {
    const fileUrl =
      file !== null
        ? file.link ||
          (typeof file.file === "object" && (file.file as any).link) ||
          (typeof file.file === "string" && file.file)
        : null;

    if (!fileUrl || file === null) {
      message.error("Невозможно выгрузить несохраненные файл");
      return;
    }

    setFilesLoading((prevState) => ({ ...prevState, [file.id]: true }));
    await downloadFile(fileUrl, file.name).catch(() => {
      message.error("Невозможно выгрузить файл");
    });
    setFilesLoading((prevState) => ({ ...prevState, [file.id]: false }));
  }, []);

  const slicedFilesByMaxDisplayingCount = useMemo(() => {
    if (!maxDisplayingCount) return [];
    return files.slice(0, maxDisplayingCount);
  }, [maxDisplayingCount, files]);

  const openConfirmDownloadModal = useCallback((downloadedFile: IFile) => {
    setDownloadedFile(downloadedFile);
    setIsOpenConfirmationModal(true);
  }, []);

  const onConfirmDownload = useCallback(() => {
    handleDownloadFile(downloadedFile);
    setDownloadedFile(null);
    closeConfirmationModal();
  }, [downloadedFile, closeConfirmationModal]);

  const downloadIfCan = useCallback(
    (file: IFile, i: number) => {
      if (isFileViewer) {
        setFileViewIndex(i);
        return;
      }
      if (_TODO_onDirectlyClick) {
        _TODO_onDirectlyClick(i);
        return;
      }
      if (!canExport) return;
      mustConfirmDownload ? openConfirmDownloadModal(file) : handleDownloadFile(file);
    },
    [canExport, handleDownloadFile, mustConfirmDownload, openConfirmDownloadModal, _TODO_onDirectlyClick, isFileViewer]
  );

  const [fileViewIndex, setFileViewIndex] = useState(-1);

  return (
    <>
      {(maxDisplayingCount ? slicedFilesByMaxDisplayingCount : files).map((file, i) => (
        <FileRow
          key={file.id}
          file={serializeFilesByName(file) as any}
          download={(file) => downloadIfCan(file, i)}
          isLoading={filesLoading[file.id]}
          remove={removeFile}
          canRemove={!isDisabled && canRemove}
        />
      ))}
      <ConfirmationModal isOpen={isOpenConfirmationModal} onClose={closeConfirmationModal} action={onConfirmDownload}>
        Вы действительно хотите выгрузить файл?
      </ConfirmationModal>
      {isFileViewer && (
        <FileViewer
          startIndex={fileViewIndex}
          isOpen={fileViewIndex !== -1}
          onClose={() => setFileViewIndex(-1)}
          files={files as any}
        />
      )}
    </>
  );
};

export default React.memo(FilesList);
