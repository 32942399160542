import React, { useMemo } from "react";
import { Field, Form } from "react-final-form";

import InputNumber from "components/UI/atoms/InputNumber";
import Select, { IOption } from "components/UI/atoms/Select";

import {
  COEFFICIENT_MTR_CLARIFICATION_OPTIONS,
  COEFFICIENT_SMR_CLARIFICATION_OPTIONS,
  COEFFICIENT_STEP_OPTIONS,
} from "../../HandlerCoefficients";
import Dropdown from "shared/ui/atoms/Dropdown/Dropdown";
import Actions from "shared/ui/controls/Actions/Actions";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import InputBase from "shared/ui/inputs/InputBase";

import { ReactComponent as ArrowDownSelect } from "images/icons/arrowDownSelect.svg";

import styles from "./HandlerCoefficientsForm.module.scss";

interface IProps {
  values?: any;
  onSubmit: (vals: any) => void;
  onCancel: () => void;
  isPending?: boolean;
  coefficientsForSteps: IOption[];
}

const HandlerCoefficientsForm: React.FC<IProps> = ({ values, onSubmit, onCancel, isPending, coefficientsForSteps }) => {
  const coefficientSteps = useMemo(() => COEFFICIENT_STEP_OPTIONS.concat(coefficientsForSteps), [coefficientsForSteps]);
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={values}
      key={values}
      render={({ handleSubmit, form }) => {
        const onCheckAllSMR = (checked: boolean) => {
          COEFFICIENT_SMR_CLARIFICATION_OPTIONS.forEach((option) => {
            form.change(option.id, checked);
          });
        };
        const onCheckAllMTR = (checked: boolean) => {
          COEFFICIENT_MTR_CLARIFICATION_OPTIONS.forEach((option) => {
            form.change(option.id, checked);
          });
        };
        const onCheckSMRClarification = (checked: boolean) => {
          if (!checked) {
            form.change("_allSMRChecked", false);
            return;
          }
          const values = form.getState().values;
          if (COEFFICIENT_SMR_CLARIFICATION_OPTIONS.every((option) => Boolean(values[option.id]))) {
            form.change("_allSMRChecked", true);
          }
        };
        const onCheckMTRClarification = (checked: boolean) => {
          if (!checked) {
            form.change("_allMTRChecked", false);
            return;
          }
          const values = form.getState().values;
          if (COEFFICIENT_MTR_CLARIFICATION_OPTIONS.every((option) => Boolean(values[option.id]))) {
            form.change("_allMTRChecked", true);
          }
        };
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className={styles.form}>
              <div className={styles.row}>
                <Field
                  name="name"
                  component={InputBase as any}
                  placeholder="Название и/или обоснование коэффициента*"
                  required
                  className={styles.name}
                />
                <Field
                  name="coefficient_step"
                  required
                  render={({ input, meta }) => (
                    <Select
                      options={coefficientSteps}
                      placeholder="Применять к*"
                      input={input}
                      meta={meta}
                      classNameOptions={styles.selectOptions}
                    />
                  )}
                />
                <Field
                  name="value"
                  required
                  render={({ input }) => (
                    <InputNumber
                      placeholder="Значение"
                      value={input.value}
                      setValue={input.onChange}
                      decimalPlace={100}
                      hideControls
                      containerClassName={styles.coefficient}
                    />
                  )}
                />
                <div className={styles.checks}>
                  <Field
                    name="is_smr"
                    render={({ input }) => (
                      <>
                        <Checkbox
                          checked={input.value}
                          onCheck={(e) => {
                            input.onChange(e.target.checked);
                          }}
                          labelPlacement={"right" as any}
                        >
                          СМР
                        </Checkbox>
                      </>
                    )}
                  />
                  <Field
                    name="is_mtr"
                    render={({ input }) => (
                      <>
                        <Checkbox
                          checked={input.value}
                          onCheck={(e) => {
                            input.onChange(e.target.checked);
                          }}
                          labelPlacement={"right" as any}
                        >
                          МТР
                        </Checkbox>
                      </>
                    )}
                  />
                </div>
                <div className={styles.clarificationContainer}>
                  {form.getFieldState("is_smr")?.value ? (
                    <Dropdown
                      placeholder={(() => {
                        const enabledClarificationsCount = COEFFICIENT_SMR_CLARIFICATION_OPTIONS.reduce(
                          (enabledCount, option) => {
                            const currentOpitonState = form.getFieldState(option.id);
                            if (currentOpitonState?.value) {
                              enabledCount++;
                            }
                            return enabledCount;
                          },
                          0
                        );
                        if (!enabledClarificationsCount) return "Уточнение СМР";
                        return `Уточнение СМР (${enabledClarificationsCount})`;
                      })()}
                      classNameContainer={styles.clarification}
                      classNameContent={styles.clarificationOptions}
                      icon={<ArrowDownSelect />}
                      strategy="visibility"
                    >
                      <Field
                        name="_allSMRChecked"
                        render={({ input }) => (
                          <>
                            <Checkbox
                              checked={input.value}
                              onCheck={(e) => {
                                input.onChange(e.target.checked);
                                onCheckAllSMR(e.target.checked);
                              }}
                              labelPlacement={"right" as any}
                            >
                              Выбрать все
                            </Checkbox>
                          </>
                        )}
                      />
                      {COEFFICIENT_SMR_CLARIFICATION_OPTIONS.map((option) => (
                        <Field
                          name={option.id as string}
                          key={option.id}
                          render={({ input }) => (
                            <Checkbox
                              title={option.description}
                              checked={input.value}
                              onCheck={(e) => {
                                const checked = e.target.checked;
                                input.onChange(checked);
                                onCheckSMRClarification(checked);
                              }}
                              labelPlacement={"right" as any}
                            >
                              {option.name}
                            </Checkbox>
                          )}
                        />
                      ))}
                    </Dropdown>
                  ) : null}
                  {form.getFieldState("is_mtr")?.value ? (
                    <Dropdown
                      placeholder={(() => {
                        const enabledClarificationsCount = COEFFICIENT_MTR_CLARIFICATION_OPTIONS.reduce(
                          (enabledCount, option) => {
                            const currentOpitonState = form.getFieldState(option.id);
                            if (currentOpitonState?.value) {
                              enabledCount++;
                            }
                            return enabledCount;
                          },
                          0
                        );
                        if (!enabledClarificationsCount) return "Уточнение МТР";
                        return `Уточнение МТР (${enabledClarificationsCount})`;
                      })()}
                      classNameContainer={styles.clarification}
                      classNameContent={styles.clarificationOptions}
                      icon={<ArrowDownSelect />}
                      strategy="visibility"
                    >
                      <Field
                        name="_allMTRChecked"
                        render={({ input }) => (
                          <>
                            <Checkbox
                              checked={input.value}
                              onCheck={(e) => {
                                input.onChange(e.target.checked);
                                onCheckAllMTR(e.target.checked);
                              }}
                              labelPlacement={"right" as any}
                            >
                              Выбрать все
                            </Checkbox>
                          </>
                        )}
                      />
                      {COEFFICIENT_MTR_CLARIFICATION_OPTIONS.map((option) => (
                        <Field
                          name={option.id as string}
                          key={option.id}
                          render={({ input }) => (
                            <Checkbox
                              title={option.description}
                              checked={input.value}
                              onCheck={(e) => {
                                const checked = e.target.checked;
                                input.onChange(checked);
                                onCheckMTRClarification(checked);
                              }}
                              labelPlacement={"right" as any}
                            >
                              {option.name}
                            </Checkbox>
                          )}
                        />
                      ))}
                    </Dropdown>
                  ) : null}
                </div>
                <Field
                  name="including_items_costs"
                  render={({ input }) => (
                    <>
                      <Checkbox
                        checked={Boolean(input.value)}
                        onCheck={(e) => {
                          input.onChange(e.target.checked);
                        }}
                        labelPlacement={"right" as any}
                        className={styles.sumCheckbox}
                      >
                        Добавить ЛСР
                      </Checkbox>
                    </>
                  )}
                />

                <Actions
                  canSave
                  onSave={handleSubmit}
                  color="blue"
                  className={styles.actions}
                  canRemove
                  onRemoveDirectly={onCancel}
                  deleteLabel="Отменить"
                  disabled={isPending}
                />
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};

export default HandlerCoefficientsForm;
