import { HandBookLocalEditings } from "../model/slice";

import { IHandBook } from "../model/types";

export const handbooksModifyWithEditings = (handbooks: IHandBook[], editings: HandBookLocalEditings) => {
  return (
    handbooks
      // Без удалённых
      .filter((h) => !editings.deletions[h.id])
      // С изменёнными полями
      .map((h) => {
        if (editings.changes[h.id]) {
          return Object.assign({}, h, editings.changes[h.id]);
        }
        return h;
      })
      // @ts-ignore | С формами новых строк
      .concat(editings.new)
  );
};
