import { Redirect, Route, Switch } from "react-router-dom";

import HandBookEditButton from "./features/HandBookEditButton/HandBookEditButton";
import { IHandbookFormField } from "./ui/HandbookForm/HandbookForm";
import HandBookImport from "./widgets/HandBookImport/HandBookImport";
import HandBookWorks, { IHandbookColumn } from "./widgets/HandBookWorks/HandBookWorks";
import CustomBreadcrumbs from "entities/CustomBreadcrumbs/CustomBreadcrumbs";
import { ALL_MEASURES, MEASURES } from "entities/MeasureSelect/MeasureSelect";
import BreadcrumbsObjectSelect from "features/templates/BreadcrumbsSelects/BreadcrumbsObjectSelect";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import TabBar from "shared/ui/controls/TabBar/TabBar";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { useHandbookBreadcrumbs } from "./hooks/useHandbookBreadcrumbs";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./HandBook.module.scss";

//TODO filter type
const HandBook = () => {
  const routePrefix = useUrlModuleWithObjectId();
  const { title, showObjectSelect, constructLink } = useHandbookBreadcrumbs();

  return (
    <TemplateBase
      breadcrumbs={
        <CustomBreadcrumbs title={title} subtitle="Справочники">
          {showObjectSelect && <BreadcrumbsObjectSelect constructLink={constructLink} />}
        </CustomBreadcrumbs>
      }
    >
      <ListPageHeaderTemplate>
        <TabBar tabs={tabs} isOnlyTabsView />
        <div className={styles.headerButtonsRight}>
          <HandBookEditButton />
          <HandBookImport />
        </div>
      </ListPageHeaderTemplate>
      <Switch>
        <Route
          path={`/${routePrefix}/handbook/works`}
          render={() => (
            <HandBookWorks
              fields={workFields}
              columns={worksCols}
              defaultFilter={{ type: "work" }}
              key="work"
              colPercents={defaultCols}
              formColPercents={worksFormCols}
            />
          )}
        />
        <Route
          path={`/${routePrefix}/handbook/resources`}
          render={() => (
            <HandBookWorks
              fields={resFields}
              columns={resCols}
              defaultFilter={{ type: ["material", "equipment"] }}
              key="material"
              colPercents={resColsPercents}
              formColPercents={resFormCols}
            />
          )}
        />
        <Route
          path={`/${routePrefix}/handbook/mims`}
          render={() => (
            <HandBookWorks
              fields={mimsFields}
              columns={mimsCols}
              defaultFilter={{ type: ["machine", "transport"] }}
              key="mim"
              colPercents={mimsColsPercents}
              formColPercents={mimsFormCols}
            />
          )}
        />
        <Redirect to={`/${routePrefix}/handbook/works`} />
      </Switch>
    </TemplateBase>
  );
};

export default HandBook;

const tabs = [
  { text: "Работы", link: "./works" },
  { text: "Ресурсы", link: "./resources" },
  { text: "МиМ", link: "./mims" },
];

const validateName = (val: string | undefined) => {
  if (!val?.length) {
    return "Наименование не может быть пустым";
  }
  return;
};

const validateRequired = (val: string | undefined) => {
  if (!val?.toString().length) {
    return "Поле обязательно";
  }
  return;
};

const validatePrice = (val: string | undefined) => {
  // Цена не обязательна для заполнения
  if (!val?.toString().length) return;
  if (isNaN(parseFloat(val))) {
    return "Введите число";
  }
  if (parseFloat(val) < 0) {
    return "Цена не может быть отрицательной";
  }
  return;
};

const defaultCols = [75, 10, 15]; //[65, 7.5, 7.5, 7.5, 7.5, 5];
const worksCols: IHandbookColumn[] = [
  { header: { title: "Наименование", filterName: "name" }, render: (item) => item.name },
  { header: { title: "Ед. изм." }, render: (item) => item.measure, isCentered: true },
  //{ header: { title: "Кол-во" }, render: (item) => item.count, isCentered: true },
  {
    header: { title: `Цена ${LOCALIZATION_CONFIG.currency}` },
    render: (item) => transformDigitToFinancial(item.price),
    isRighted: true,
  },
  /* { header: { title: "Атрибут 1" }, render: (item) => item.segment?.parent?.name, isNoBreak: true },
  {
    header: { title: "Атрибут 2" },
    render: (item) => item.segment?.subsegments?.map((el) => el.name).join(", "),
    isNoBreak: true,
  }, */
];
const worksFormCols = [2, 73, 10, 15];
const workFields: IHandbookFormField[] = [
  {
    label: "Наименование",
    name: "name",
    type: "text",
    validate: validateName,
  },
  { label: "Ед. изм.", name: "measure", type: "select", options: MEASURES, validate: validateRequired },
  //{ label: "Кол-во", name: "count", type: "text" },
  {
    label: "Цена",
    name: "price",
    type: "text",
    validate: validatePrice,
  },
  /*  { label: "Атрибут 1", name: "_temp", type: "text" },
  { label: "Атрибут 2", name: "_temp", type: "text" }, */
];

const resColsPercents = [47, 11, 15, 10, 15];
const resFormCols = [2, 45, 11, 15, 10, 15];
const resCols: IHandbookColumn[] = [
  {
    header: { title: "Наименование", filterName: "name" },
    render: (item) => [item.name, item.brand, item.code, item.producer].filter((x) => x?.length > 0).join(". "),
  },
  { header: { title: "Тип изделия", filterName: "product_type" }, render: (item) => item.product_type },

  {
    header: { title: "Категория" },
    render: (item) => typesNamesDict[item.type as keyof typeof typesNamesDict],
  },
  { header: { title: "Ед. изм." }, render: (item) => item.measure, isCentered: true },

  {
    header: { title: `Цена ${LOCALIZATION_CONFIG.currency}` },
    render: (item) => transformDigitToFinancial(item.price),
    isRighted: true,
  },
];
const resFields: IHandbookFormField[] = [
  {
    label: "Наименование",
    name: "name",
    type: "text",
    validate: validateName,
  },
  { label: "Тип изделия", name: "product_type", type: "text" },
  {
    label: "Категория",
    name: "type",
    type: "select",
    options: [
      { id: "equipment", name: "Оборудование" },
      { id: "material", name: "Материал" },
    ],
    validate: validateRequired,
  },
  { label: "Ед. изм.", name: "measure", type: "select", options: MEASURES, validate: validateRequired },
  {
    label: "Цена",
    name: "price",
    type: "text",
    validate: validatePrice,
  },
];

const mimsColsPercents = [53, 12, 12, 10, 15];
const mimsFormCols = [2, 51, 12, 12, 10, 15];
const mimsCols: IHandbookColumn[] = [
  {
    header: { title: "Наименование", filterName: "name" },
    render: (item) => [item.name, item.code, item.brand].filter((x) => x?.length > 0).join(". "),
  },
  { header: { title: "Тип", filterName: "product_type" }, render: (item) => item.product_type },

  {
    header: { title: "Категория" },
    render: (item) => (
      <span title={typesNamesDict[item.type as keyof typeof typesNamesDict]}>
        {typesNamesDict[item.type as keyof typeof typesNamesDict].substring(0, 1)}
      </span>
    ),
    isCentered: true,
  },
  { header: { title: "Ед. изм." }, render: (item) => item.measure, isCentered: true },
  {
    header: { title: `Цена ${LOCALIZATION_CONFIG.currency}` },
    render: (item) => transformDigitToFinancial(item.price),
    isRighted: true,
  },
];
const mimsFields: IHandbookFormField[] = [
  {
    label: "Наименование",
    name: "name",
    type: "text",
    validate: validateName,
  },
  { label: "Тип", name: "product_type", type: "text" },
  {
    label: "Категория",
    name: "type",
    type: "select",
    options: [
      { id: "machine", name: "МиМ" },
      { id: "transport", name: "Транспорт" },
    ],
    validate: validateRequired,
  },
  { label: "Ед. изм.", name: "measure", type: "select", options: ALL_MEASURES, validate: validateRequired },
  {
    label: "Цена",
    name: "price",
    type: "text",
    validate: validatePrice,
  },
];

const typesNamesDict = {
  equipment: "Оборудование",
  machine: "МиМ",
  material: "Материал",
  transport: "Транспорт",
};
