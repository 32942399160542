import { message } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deletePlanIsLoadingSelector } from "./model/selectors";
import { deleteSectionPlan } from "./model/thunks";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { useCanDeletePlan } from "./hooks/useCanDeletePlan";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./DeletePlan.module.scss";

interface IProps {
  buildingId: string;
  planId: number;
  onClose?: () => void;
}

const DeleteSectionPlan = ({ buildingId, planId, onClose }: IProps) => {
  const dispatch = useDispatch();
  const key = generateStorageKey({ buildingId, id: planId, type: "section" });
  const isLoading = useSelector(deletePlanIsLoadingSelector)[key];
  const [isModal, setIsModal] = useState(false);

  const onSuccess = () => {
    onClose?.();
    message.success("План удален");
  };

  const onDelete = () => {
    dispatch(deleteSectionPlan({ buildingId, planId, successCallback: onSuccess }));
  };

  const canDelete = useCanDeletePlan();

  if (!canDelete) {
    return null;
  }

  return (
    <div className={styles.marginTop}>
      <div className={styles.title}>Действия</div>
      <ButtonBase onClick={() => setIsModal(true)} danger isLoading={isLoading}>
        Удалить план
      </ButtonBase>
      <ConfirmModal
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        action={onDelete}
        title="Подтвердите действие"
        variant="secondary"
        acceptButtonText="Удалить"
      >
        Вы действительно хотите удалить план?
      </ConfirmModal>
    </div>
  );
};

export default DeleteSectionPlan;
