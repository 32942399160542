
import React, { ReactNode } from "react";
import cn from "classnames";


import Modal from "../../shared/ui/modal/Modal";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./ProductInfoModal.module.scss";

export interface IProductInfoModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onOpenEditing: () => void;
  canEdit: boolean;
  section?: string;
  number?: number;
  justification?: string;
  expenditureName?: string;
  price?: string;
  count?: string;
  //
  title?: string;
  labelBudget?: string;
  labelCount?: string;
  renderAdditionalControls?: (onClose: () => void) => ReactNode;
  /** Сначала название родительского сегмента, затем дочернего */
  segmentNames?: string[];
}

const ProductInfoModal: React.FC<IProductInfoModalProps> = ({
  isOpen,
  isLoading,
  onClose,
  onOpenEditing,
  canEdit,
  section,
  number,
  justification,
  expenditureName,
  price,
  count,
  title = "По смете",
  labelBudget = "Бюджет по смете:",
  labelCount = "Кол-во по смете: ",
  renderAdditionalControls,
  segmentNames,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} closeOnClickOutside={true} className={styles.modal}>
      <div className={styles.body}>
        {!isLoading ? (
          <>
            <div>
              <b>Раздел: </b>
              <br />
              {section}
            </div>
            {number && (
              <div className={styles.item}>
                <b>Номер: </b>
                <br />
                {number}
              </div>
            )}
            {justification && (
              <div className={styles.item}>
                <b>Обоснование: </b>
                <br />
                {justification}
              </div>
            )}
            {expenditureName && (
              <div className={styles.item}>
                <b>Позиция: </b>
                <br />
                {expenditureName}
              </div>
            )}
            {segmentNames && (
              <div className={cn(styles.item, styles.row)}>
                {segmentNames.map((segmentName, index) => (
                  <span key={index} className={styles.segmentName}>
                    {segmentName}
                  </span>
                ))}
              </div>
            )}
            {price && (
              <div className={styles.item}>
                <b>Цена за ед. : </b>
                {splitThousands(parseFloat(price))}
              </div>
            )}
            {count && (
              <div className={styles.item}>
                <b>{labelCount}</b>
                {splitThousands(parseFloat(count), false)}
              </div>
            )}
            {price && (
              <div className={styles.item}>
                <b>{labelBudget}</b>
                {splitThousands(parseFloat(price) * parseFloat(count || ""))}
              </div>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
      <footer className={styles.footer}>
        {canEdit && (
          <div className={styles.btns}>
            {renderAdditionalControls ? renderAdditionalControls(onClose) : <div />}
            <ButtonBase primary onClick={onOpenEditing} medium>
              Изменить
            </ButtonBase>
          </div>
        )}
      </footer>
    </Modal>
  );
};

export default React.memo(ProductInfoModal);
