import { AtLeastOne } from "types/utilTypes/atLeastOne";

interface IProps {
  buildingId: string | number;
  sectionId: number | string;
  expenditureId: number;
  year: number;
  month: number;
  stockId: number;
  betonId: number;
  type: string;
  ticketId: string | number;
  plan_work: string | number;
  plan_group: string | number;
  fact_work: string | number;
  fact_group: string | number;
  week: number | string;
  startAt: string;
  endAt: string;
  daysCount: number;
  projectId: string | number;
  id: number | string;
  status: string;
  objectId: string | number;
  name: string;
}

export const generateStorageKey = (props: AtLeastOne<IProps>) => {
  const sortedEntries = Object.entries(props).sort(([key1], [key2]) => {
    if (key1 > key2) {
      return 1;
    } else {
      return -1;
    }
  });

  return sortedEntries.map(([_, value]) => value).join("_");
};
