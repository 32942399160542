import { useEffect, useState } from "react";

import { IEstimateTargetWithType } from "../SpecificationAddRelationsModal";

interface IProps {
  tab: string;
  initialSelecteds: { estimate: IEstimateTargetWithType | null; rd: IEstimateTargetWithType[] } | null;
}

export const useSpecificationRelationsSelectTarget = ({ tab, initialSelecteds }: IProps) => {
  const [selectedEstimateTarget, setSelectedEstimateTarget] = useState<IEstimateTargetWithType | null>(
    initialSelecteds?.estimate || null
  );
  const [selectedRdTarget, setSelectedRdTarget] = useState<IEstimateTargetWithType[]>(initialSelecteds?.rd || []);
  useEffect(() => {
    if (initialSelecteds) {
      setSelectedEstimateTarget(initialSelecteds.estimate);
      setSelectedRdTarget(initialSelecteds.rd);
    } else {
      setSelectedEstimateTarget(null);
      setSelectedRdTarget([]);
    }
  }, [tab, initialSelecteds]);

  const onSelectExpenditure = (target: IEstimateTargetWithType) => {
    if (tab === "rd") {
      setSelectedRdTarget((prev) => {
        if (prev.some((el) => el.expenditureId === target.expenditureId)) {
          return prev.filter((el) => el.expenditureId !== target.expenditureId);
        } else {
          return [...prev, target];
        }
      });
    } else {
      setSelectedEstimateTarget(target);
    }
  };

  return {
    onSelectExpenditure,
    selectedEstimateTarget,
    selectedRdTarget,
  };
};
