import React, { ReactNode } from "react";

import AddRelationToProduct from "components/UI/_TODO/AddRelationToProduct";
import MatchingIcon from "../../../../_LEGACY/UI/MatchingIcon/MatchingIcon";

import ProductInfoModal, { IProductInfoModalProps } from "entities/ProductInfoModal/ProductInfoModal";
import { useMatchingModalExpenditure } from "./useMatchingModalExpenditure";

import { IExpenditure } from "types/interfaces/Expenditure";

import { getMatchingStatus } from "./utils";

import styles from "./MatchingModal.module.scss";

export interface IMatchingInfo {
  estimate_expenditure: Partial<IExpenditure>;
  estimate_expenditure_id: number;
  created_from_estimate: boolean;
  is_removed: boolean;
  is_default?: boolean;
}

interface IProps {
  info: IMatchingInfo;
  objectId: string;
  updateProducts: () => void;
  readOnly: boolean;
  type: string;
  renderCustomMatchingModal?: (isOpen: boolean, onClose: () => void) => ReactNode;
  customOpenBehavior?: (info: IMatchingInfo) => "info" | "infoWithEdit" | "matching";
  customProductInfoModalProps?: Partial<IProductInfoModalProps>;
}

const MatchingModal: React.FC<IProps> = ({
  info: rawInfo,
  readOnly,
  objectId,
  type,
  updateProducts,
  renderCustomMatchingModal,
  customOpenBehavior,
  customProductInfoModalProps,
}) => {
  //кто это писал как это вообще разделять между собой
  const [matchingModalOpen, setMatchingModalOpen] = React.useState(false);
  const [matchingOpen, setMatchingOpen] = React.useState(false);
  const { info, isLoading } = useMatchingModalExpenditure({ rawInfo, isActive: matchingModalOpen, objectId });

  const openMatchingModal = () => {
    setMatchingModalOpen(true);
  };

  const closeMatchingModal = () => {
    setMatchingModalOpen(false);
  };

  const openMatching = () => {
    setMatchingModalOpen(false);
    setMatchingOpen(true);
  };

  const closeMatching = () => {
    setMatchingOpen(false);
  };

  const onClickByMatchingIcon = () => {
    if (customOpenBehavior) {
      if (customOpenBehavior(info) === "info") {
        openMatchingModal();
      }
      if (customOpenBehavior(info) === "infoWithEdit") {
        openMatchingModal();
        //TODO edit = true
      }
      if (customOpenBehavior(info) === "matching") {
        openMatching();
      }
      return;
    }
    if (info.estimate_expenditure) {
      openMatchingModal();
    } else if (!readOnly) {
      openMatching();
    }
  };

  const onOpenEditMatching = () => {
    openMatching();
  };

  const canEdit =
    !!info.estimate_expenditure?.type &&
    ((!info.created_from_estimate && !readOnly) ||
      info.estimate_expenditure.is_default === true ||
      info.estimate_expenditure.is_default === "True");

  const infoSegmentNames = info?.estimate_expenditure?.segment ? [info.estimate_expenditure.segment.parent?.name, info.estimate_expenditure.segment.name].filter((n) => n?.length > 0) : undefined

  return (
    <div>
      {!readOnly && matchingOpen && !renderCustomMatchingModal && (
        <AddRelationToProduct /* @ts-ignore */
          idAddRelation={info}
          type={type} /* @ts-ignore */
          chosenMatchingProduct={info}
          updateProducts={updateProducts}
          handleClose={closeMatching}
          objectId={objectId}
          editable={true}
        />
      )}
      <ProductInfoModal
        isOpen={matchingModalOpen}
        onClose={closeMatchingModal}
        onOpenEditing={onOpenEditMatching}
        canEdit={canEdit}
        section={(info?.estimate_expenditure as IExpenditure)?.section}
        number={(info?.estimate_expenditure as IExpenditure)?.number}
        justification={(info?.estimate_expenditure as IExpenditure)?.justification}
        expenditureName={(info?.estimate_expenditure as IExpenditure)?.name}
        price={(info?.estimate_expenditure as IExpenditure)?.price}
        count={(info?.estimate_expenditure as IExpenditure)?.count}
        isLoading={isLoading}
        segmentNames={infoSegmentNames}
        {...customProductInfoModalProps}
      />

      {
        <MatchingIcon
          className={styles.matchingIcon}
          onClick={onClickByMatchingIcon}
          status={getMatchingStatus(info)}
          disabled={info.is_removed}
        />
      }
      {renderCustomMatchingModal && renderCustomMatchingModal(matchingOpen, () => setMatchingOpen(false))}
    </div>
  );
};

export default MatchingModal;
