import { urlModulesType, useUrlModule } from "utils/hooks/useUrlModule";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

export const useHandbookBreadcrumbs = () => {
  const routeModule = useUrlModule();
  const routePrefix = useUrlModuleWithObjectId();

  const title = titlesMap[routeModule];

  const showObjectSelect = routeModule === "objects";

  const constructLink = ({ objectId }: { objectId: string | number }) => `/objects/${objectId}/handbook/works`;

  return {
    title,
    showObjectSelect,
    constructLink,
  };
};

//TODO сделать с этим что то на уровне компонента template base или breadcrumbs
const titlesMap: Record<urlModulesType, string> = {
  constructing: "Управление",
  "construction-control": "Стройконтроль",
  objects: "Производство",
  purchases: "Закупки",
  tasks: "Задачи",
};
