import { debounce } from "lodash";
import { ReactNode } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";

import Select, { IOption } from "components/UI/atoms/Select";
import { handBookActions } from "pages/HandBook/model/slice";
import { localChangeHandbook } from "pages/HandBook/model/thunks";

import { useTypedSelector } from "app/store/typedUseSelector";
import { FormApi } from "final-form";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import InputBase, { INPUT_BASE_VARIANTS } from "shared/ui/inputs/InputBase";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";

import styles from "./HandbookForm.module.scss";

//TODO сделать нормальный шаред компонент который рендерит поля формы из массива
export interface IHandbookFormField {
  label?: string;
  name: string;
  /** Возвращает текст ошибки, если поле заполнено некорректно */
  validate?: (val: any) => undefined | string;
  type: "text" | "select";
  options?: IOption[];
  render?: (form: FormApi<Record<string, any>, Partial<Record<string, any>>>) => ReactNode;
}

interface IProps {
  fields: IHandbookFormField[];
  colPercents: number[];
  item: any;
}

const HandbookForm = ({ fields, colPercents, item }: IProps) => {
  const dispatch = useDispatch();
  const editings = useTypedSelector((state) => state.handBookReducer.editings);

  const submit = (vals: any, { getState }: any) => {
    const { initialValues } = getState();
    const changedValues: Record<string, unknown> = {};

    Object.keys(vals).forEach((key) => {
      if (vals[key] !== initialValues[key]) {
        changedValues[key] = vals[key];
      }
    });
    if (Object.keys(changedValues).length > 0) {
      dispatch(localChangeHandbook(item.id, changedValues));
    }
  };

  const onCheckItem = () => {
    dispatch(handBookActions.localCheckItem({ id: item.id, isChecked: !Boolean(editings.checked[item.id]) }));
  };

  return (
    <Form
      initialValues={item}
      onSubmit={submit}
      render={({ form }) => {
        const delayedFormSubmit = debounce(() => {
          form.submit();
        }, 200);
        return (
          <div className={styles.root}>
            <RowGrid percents={colPercents} className={styles.row}>
              <Checkbox onCheck={onCheckItem} checked={editings.checked[item.id]} />
              {fields.map((el) => {
                if (el.type === "text") {
                  return (
                    <Field
                      name={el.name}
                      key={el.name}
                      validate={el.validate}
                      render={({ input, meta }) => (
                        <InputBase
                          input={{
                            ...input,
                            onChange: (...args) => {
                              input.onChange(...args);
                              delayedFormSubmit();
                            },
                          }}
                          meta={meta}
                          variant={INPUT_BASE_VARIANTS.SECONDARY}
                          classNameInput={styles.textInput} /* label={el.label} */
                        />
                      )}
                    />
                  );
                }
                if (el.type === "select") {
                  return (
                    <Field
                      name={el.name}
                      key={el.name}
                      validate={el.validate}
                      render={({ input, meta }) => (
                        <Select
                          input={{
                            ...input,
                            onChange: (...args) => {
                              input.onChange(...args);
                              delayedFormSubmit();
                            },
                          }}
                          meta={meta}
                          options={el.options ?? []}
                          /* label={el.label} */
                        />
                      )}
                    />
                  );
                }
                return null;
              })}
              {/* <Actions canSave canRemove onRemoveDirectly={onCancel} onSave={() => form.submit()} /> */}
            </RowGrid>
          </div>
        );
      }}
    />
  );
};

export default HandbookForm;
