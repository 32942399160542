import cn from "classnames";
import React from "react";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Modal from "shared/ui/modal/Modal";

import { ReactComponent as WarningIcon } from "shared/assets/icons/Icon Warning.svg";
import { ReactComponent as ExcelIcon } from "shared/assets/icons/Icon-ChooseImportFile.svg";
import { ReactComponent as SuccessIcon } from "shared/assets/icons/Icon-success.svg";

import styles from "./ImportModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  isErr: boolean;
  isSuccess: boolean;
  countOfPositions: number;
  onSuccess: () => void;
  errList: any;
  onDownloadSample: () => void;
  onChangeInput: React.ChangeEventHandler<HTMLInputElement>;
}

//TODO вынести и пошарить UI с обработчиком
const ImportModal = ({
  isOpen,
  onClose,
  isLoading,
  isSuccess,
  isErr,
  countOfPositions,
  onSuccess,
  errList,
  onDownloadSample,
  onChangeInput,
}: IProps) => {
  if (isSuccess) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Файл успешно загружен"
        className={styles.modal}
        headerClassName={styles.title}
        closeOnClickOutside
        darkShadow
      >
        <div>
          <SuccessIcon className={cn(styles.icon, styles.success)} />
          <p className={styles.modaltext}>В систему успешно добавлены позиции: {countOfPositions}</p>
        </div>
        <div className={styles.buttons} style={{ justifyContent: "center" }}>
          <ButtonBase primary onClick={onSuccess}>
            Хорошо!
          </ButtonBase>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isLoading ? "Идет обработка файла" : isErr ? "Ошибка обработки файла" : "Выберите файл для загрузки"}
      className={isErr ? styles.err : styles.modal}
      headerClassName={styles.title}
      closeOnClickOutside
      darkShadow
    >
      {isLoading ? (
        <Spinner />
      ) : !isErr ? (
        <>
          <ExcelIcon className={styles.icon} />
          <p className={styles.modaltext}>
            Импорт происходит согласно шаблону.
            <br />
            Заполните шаблон и импортируйте файл xlsx
          </p>
        </>
      ) : (
        <>
          <WarningIcon className={styles.icon} />
          <p className={styles.modaltext}>Позиции не сохранены. Требуются исправления:</p>
          <div className={styles.titles}>
            <h4>Строка</h4>
            <h4>Ошибка</h4>
          </div>
          <div className={styles.listbox}>
            {Object.keys(errList).map((el) => {
              return (
                <div className={styles.table} key={el}>
                  <div className={styles.string}>
                    <h4>{el}</h4>
                  </div>
                  <div className={styles.mistake}>{errList[el]}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className={styles.buttons}>
        <ButtonBase secondary onClick={onDownloadSample}>
          Скачать шаблон
        </ButtonBase>
        <label>
          <div className={styles.btn}>Выбрать файл</div>
          <input type="file" className={styles.input} onChange={onChangeInput} />
        </label>
      </div>
    </Modal>
  );
};

export default ImportModal;
