import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  objectsBreadcrumbsLoadingSelector,
  objectsBreadcrumbsSelector,
  objectsByProjectSelector,
} from "redux/modules/common/building/objects";

import CustomBreadCrumbsMenu from "entities/CustomBreadcrumbs/CustomBreadCrumbsMenu";

import { IIdAndName } from "types/idAndName";
import { IRouterParamsWithObjectId } from "types/routerTypes";

interface IProps<
  Params extends { objectId: string | number; projectId: string } = { objectId: string | number; projectId: string }
> {
  constructLink: (params: Params) => string;
  customFirstOption?: IIdAndName & { link: string };
  isObjectsListByProjects?: boolean;
  disabled?: boolean;
}

const BreadcrumbsObjectSelect: React.FC<IProps> = ({
  constructLink,
  customFirstOption,
  isObjectsListByProjects,
  disabled,
}) => {
  const {
    objectId: urlObjectId,
    buildingId,
    projectId,
  } = useParams<IRouterParamsWithObjectId & { buildingId?: string; projectId: string }>();
  const objectId = urlObjectId || buildingId;

  const objects = useSelector(objectsBreadcrumbsSelector);
  const isObjectsLoading = useSelector(objectsBreadcrumbsLoadingSelector);
  const objectsByProject = useSelector(objectsByProjectSelector)[projectId] ?? [];

  const options = useMemo(() => {
    const candidate: IIdAndName[] = (isObjectsListByProjects ? objectsByProject : objects?.results) ?? [];
    const result = candidate.map((el) => ({ ...el, link: constructLink({ projectId, objectId: el.id }) }));
    if (customFirstOption) {
      return [customFirstOption, ...result];
    }
    return result.filter((el) => el.id !== +objectId!);
  }, [constructLink, customFirstOption, isObjectsListByProjects, projectId, objectId, objectsByProject, objects]);

  const activeObjectName = useMemo(() => {
    const candidate: IIdAndName[] = (isObjectsListByProjects ? objectsByProject : objects?.results) ?? [];
    return candidate.find((o: IIdAndName) => +o.id == +objectId!)?.name || "Все объекты";
  }, [objects?.results, objectId, isObjectsListByProjects, objectsByProject]);

  return <CustomBreadCrumbsMenu disabled={disabled} options={options} selectedOption={activeObjectName} />;
};

export default React.memo(BreadcrumbsObjectSelect);
