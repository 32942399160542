import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartTabSelector } from "redux/modules/common/chart/selectors";
import { configureChart, getChartStatuses } from "redux/modules/common/chart/thunks";

import { useUrlModule } from "utils/hooks/useUrlModule";

export const useChartConfig = () => {
  const dispatch = useDispatch();
  const module = useUrlModule();
  const tab = useSelector(chartTabSelector);

  useEffect(() => {
    // Сброс фильтров при переключении модулей и табов
    dispatch(configureChart(module));
  }, [module, tab]);

  useEffect(() => {
    dispatch(getChartStatuses());
  }, [module]);
};
