import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActionsSelector, chartTabSelector } from "redux/modules/common/chart/selectors";
import { updateChartActions } from "redux/modules/common/chart/thunks";
import { ChartActions } from "redux/modules/common/chart/types";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import ButtonBase from "shared/ui/controls/ButtonBase";
import { Switch } from "shared/ui/inputs/Switch/Switch";

import { CHART_ACTIONS_VISIBILITY_BY_TAB } from "../../constants";

import { ReactComponent as FilterIcon } from "shared/assets/icons/Filter.svg";

import styles from "./ChartTreeFilter.module.scss";

const ChartTreeFilter: React.FC = () => {
  const dispatch = useDispatch();
  const chartActions = useSelector(chartActionsSelector);
  const tab = useSelector(chartTabSelector);
  const visibility = tab && CHART_ACTIONS_VISIBILITY_BY_TAB[tab];
  /** Строгое сравнение с undefined специально.
  Если action === undefined, он не отображается в "Действиях с графиком"  */
  const checkSwitchVisibility = (key: ChartActions) => chartActions[key] !== undefined && visibility?.[key];
  const hasShowExpendituresSwitch = checkSwitchVisibility(ChartActions.show_expenditures_in_tree);
  const hasHideExpendituresWithoutPlansSwitch = checkSwitchVisibility(ChartActions.hide_expenditures_without_plans);
  const hasHideExpendituresWithoutFactsSwitch = checkSwitchVisibility(ChartActions.hide_expenditures_without_facts);
  const hasHideExpendituresWithoutRemarksSwitch = checkSwitchVisibility(ChartActions.hide_expenditures_without_remarks);

  const onActionChange = (name: ChartActions, value: boolean) => {
    dispatch(updateChartActions({ name, value }));
  };

  const showMark =
    (hasHideExpendituresWithoutPlansSwitch && chartActions[ChartActions.hide_expenditures_without_plans]) ||
    (hasHideExpendituresWithoutFactsSwitch && chartActions[ChartActions.hide_expenditures_without_facts]) ||
    (hasHideExpendituresWithoutRemarksSwitch && chartActions[ChartActions.hide_expenditures_without_remarks]) ||
    (hasShowExpendituresSwitch && !chartActions[ChartActions.show_expenditures_in_tree]);

  return (
    <PopoverOverlay
      openType="click"
      placement="bottom"
      popoverBorderColor="primary"
      content={
        <div className={styles.container}>
          {hasShowExpendituresSwitch && (
            <Switch
              className={styles.action}
              onChange={(e) => onActionChange(ChartActions.show_expenditures_in_tree, e.target.checked)}
              checked={Boolean(chartActions[ChartActions.show_expenditures_in_tree])}
              label={<span className={styles.actionLabel}>Отображать расценки</span>}
            />
          )}
          {hasHideExpendituresWithoutPlansSwitch && (
            <Switch
              className={styles.action}
              onChange={(e) => onActionChange(ChartActions.hide_expenditures_without_plans, e.target.checked)}
              checked={Boolean(chartActions[ChartActions.hide_expenditures_without_plans])}
              label={<span className={styles.actionLabel}>Скрыть расценки без планов</span>}
            />
          )}
          {hasHideExpendituresWithoutFactsSwitch && (
            <Switch
              className={styles.action}
              onChange={(e) => onActionChange(ChartActions.hide_expenditures_without_facts, e.target.checked)}
              checked={Boolean(chartActions[ChartActions.hide_expenditures_without_facts])}
              label={<span className={styles.actionLabel}>Скрыть расценки без фактов</span>}
            />
          )}
          {hasHideExpendituresWithoutRemarksSwitch && (
            <Switch
              className={styles.action}
              onChange={(e) => onActionChange(ChartActions.hide_expenditures_without_remarks, e.target.checked)}
              checked={Boolean(chartActions[ChartActions.hide_expenditures_without_remarks])}
              label={<span className={styles.actionLabel}>Скрыть расценки без замечаний</span>}
            />
          )}
          <ButtonBase
            secondary
            className={styles.collapseAllButton}
            onClick={() =>
              onActionChange(ChartActions.default_tree_open_state, !chartActions[ChartActions.default_tree_open_state])
            }
          >
            {chartActions.default_tree_open_state ? "Свернуть всё" : "Развернуть всё"}
          </ButtonBase>
        </div>
      }
    >
      <div className={styles.filterEl}>
        {showMark && <div className={styles.indicator} />}
        <FilterIcon />
      </div>
    </PopoverOverlay>
  );
};

export default React.memo(ChartTreeFilter);
