import { initChartActions, initChartTreeFilters } from "redux/modules/common/chart/reducer";
import { ChartActions, IChartStatePatch } from "redux/modules/common/chart/types";

import { CHART_TREE_LVL } from "../constants";

export const CHART_CONFIG_OBJECTS: IChartStatePatch = {
  filters: {
    ...initChartTreeFilters(),
    skipBranchLvl: {
      [CHART_TREE_LVL.PROJECT]: true,
    },
  },
  actions: {
    ...initChartActions(),
    [ChartActions.show_expenditures_in_tree]: true,
    [ChartActions.plans_editing_enabled]: false,
    [ChartActions.linking_editing_enabled]: false,
    [ChartActions.hide_expenditures_without_plans]: false,
    [ChartActions.hide_expenditures_without_facts]: false,
    [ChartActions.hide_expenditures_without_remarks]: false,
  },
  module: "objects",
};
