import { message } from "antd";
import { Dispatch } from "redux";

import { handBookApi } from "./api";
import { handBookActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IHandBook, IHandBookRequest } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getHandbooks =
  ({ limit, offset, ...filter }: IHandBookRequest = {}) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    //TODO поправить типы generateStorageKey
    const key = generateStorageKey(filter as any);
    if (!getState().handBookReducer.handbooks[key]) {
      dispatch(handBookActions.setIsLoading({ key, status: true }));
    }
    handBookApi
      .getList({ limit, offset, ...filter })
      .then(({ data }) => {
        dispatch(handBookActions.setItems({ key, items: data }));
      })
      .finally(() => {
        dispatch(handBookActions.setIsLoading({ key, status: false }));
      });
  };

export const getHandbooksMore =
  ({ limit, offset, ...filter }: IHandBookRequest = {}) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    //TODO поправить типы generateStorageKey
    const key = generateStorageKey(filter as any);
    if (!getState().handBookReducer.handbooks[key]) {
      dispatch(handBookActions.setIsLoading({ key, status: true }));
    }
    handBookApi
      .getList({ limit, offset, ...filter })
      .then(({ data }) => {
        dispatch(handBookActions.setItemsMore({ key, items: data }));
      })
      .finally(() => {
        dispatch(handBookActions.setIsLoading({ key, status: false }));
      });
  };

export const editHandbook = (id: number, data: any) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ id, type: "mutate" });
  dispatch(handBookActions.setIsLoading({ key, status: true }));
  return handBookApi
    .patch(id, data)
    .then(({ data }) => {
      dispatch(handBookActions.updateItem(data));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(handBookActions.setIsLoading({ key, status: false }));
    });
};

export const deleteHandbook = (id: number) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ id, type: "mutate" });
  dispatch(handBookActions.setIsLoading({ key, status: true }));
  return handBookApi
    .delete(id)
    .then(({ data }) => {
      dispatch(handBookActions.deleteItem(id));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(handBookActions.setIsLoading({ key, status: false }));
    });
};

export const cancelEditingHandbook = () => (dispatch: Dispatch) => {
  dispatch(handBookActions.clearEditings());
  dispatch(handBookActions.setEditing(false));
};

export const applyEditingHandbook = () => async (dispatch: Dispatch, getState: () => RootState) => {
  const handbookEditings = getState().handBookReducer.editings;
  // @ts-ignore
  const deletions = Object.keys(handbookEditings.deletions).map((id) => dispatch(deleteHandbook(+id)));
  const editings = Object.entries(handbookEditings.changes).map(([id, change]) =>
    // @ts-ignore | Пропускаем patch удалённых строк
    handbookEditings.deletions[id] ? Promise.resolve() : dispatch(editHandbook(+id, change))
  );
  await Promise.allSettled([...deletions, ...editings]);
  message.success("Справочник отредактирован");
  dispatch(handBookActions.clearEditings());
  dispatch(handBookActions.setEditing(false));
  dispatch(handBookActions.invalidateKey());
};

export const localDeleteHandbook = (id: number) => (dispatch: Dispatch) => {
  dispatch(handBookActions.localDeleteItem(id));
};

export const localChangeHandbook = (id: number, change: Partial<IHandBook>) => (dispatch: Dispatch) => {
  dispatch(handBookActions.localChangeItem({ id, change }));
};

export const localDeleteCheckedHandbooks = () => (dispatch: Dispatch, getState: () => RootState) => {
  const checkedHandbooks = getState().handBookReducer.editings.checked;
  Object.entries(checkedHandbooks).forEach(([handbookId, isChecked]) => {
    if (isChecked) {
      dispatch(handBookActions.localDeleteItem(+handbookId));
      dispatch(handBookActions.localCheckItem({ id: +handbookId, isChecked: false }));
    }
  });
};

export const createHandbookItem = (vals: Partial<IHandBook>) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = getState().handBookReducer._lastUsedKey;
  return handBookApi
    .post(vals)
    .then(({ data }) => {
      dispatch(handBookActions.addItem({ item: data, key }));
      dispatch(handBookActions.localDeleteAddingItem(vals.id!));
      message.success("Запись успешно добавлена");
    })
    .catch(errorCatcher);
};
