import cn from "classnames";
import React from "react";

import styles from "./AddButton.module.scss";

type propsType = {
  text?: string;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  isDisabled?: boolean;
  textPosition?: "left" | "right";
  isWide?: boolean;
  variant?: 'default' | 'solid'
};

function AddButton({ text = "", className, onClick, isDisabled, textPosition = "right", isWide, variant}: propsType) {
  return (
    <button
      className={cn(styles.btn, className, { [styles.disabled]: isDisabled, [styles.wide]: isWide, [styles.solid]: variant === 'solid' })}
      onClick={onClick}
      disabled={isDisabled}
      type="button"
    >
      {text && textPosition === "left" && <div className={styles.text}>{text}</div>}
      <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className="addBtnBg"
          d="M17 0.700195C20.3623 0.700195 23.6491 1.69723 26.4447 3.56521C29.2403 5.4332 31.4193 8.08823 32.706 11.1946C33.9926 14.3009 34.3293 17.7191 33.6734 21.0167C33.0174 24.3144 31.3983 27.3435 29.0208 29.721C26.6433 32.0985 23.6142 33.7176 20.3165 34.3735C17.0189 35.0295 13.6007 34.6928 10.4944 33.4061C7.38804 32.1195 4.733 29.9405 2.86502 27.1449C0.997033 24.3493 0 21.0625 0 17.7002C0 13.1915 1.79107 8.8675 4.97918 5.67938C8.1673 2.49126 12.4913 0.700195 17 0.700195V0.700195Z"
          fill="#4FB1EB"
        />
        <path
          className="addBtnCross"
          d="M15.5195 26.2192V19.1821H8.48047C8.08811 19.1816 7.71299 19.0255 7.43555 18.748C7.15811 18.4706 7.00248 18.0945 7.00195 17.7021C7.00248 17.3098 7.15811 16.9337 7.43555 16.6562C7.71299 16.3788 8.08811 16.2227 8.48047 16.2222H15.5195V9.18604C15.5195 8.79324 15.6757 8.4167 15.9531 8.13867C16.2306 7.86064 16.6072 7.70392 17 7.70312C17.393 7.70392 17.7694 7.86043 18.0469 8.13867C18.3244 8.41692 18.4807 8.79404 18.4805 9.18701V16.2231H25.5215C25.9138 16.2237 26.289 16.3798 26.5664 16.6572C26.8438 16.9347 27.0014 17.3108 27.002 17.7031C27.0014 18.0955 26.8438 18.4716 26.5664 18.749C26.289 19.0265 25.9138 19.1826 25.5215 19.1831H18.4805V26.2202C18.4806 26.4146 18.4415 26.607 18.3672 26.7866C18.2929 26.9662 18.1843 27.1296 18.0469 27.2671C17.9094 27.4045 17.746 27.5136 17.5664 27.5879C17.3868 27.6622 17.1944 27.7003 17 27.7002C16.8055 27.7003 16.6133 27.6618 16.4336 27.5874C16.2539 27.513 16.0906 27.4042 15.9531 27.2666C15.8157 27.129 15.7071 26.9659 15.6328 26.7861C15.5585 26.6064 15.5193 26.4137 15.5195 26.2192Z"
          fill="white"
        />
      </svg>
      {text && textPosition === "right" && <div className={styles.text}>{text}</div>}
    </button>
  );
}

export default React.memo(AddButton);
