import axios from "axios";

export interface IDeletePlanReq {
  isGroup: boolean;
  buildingId: string;
  expenditureId: number;
  planId: number;
}

export const deletePlanApi = {
  deletePlan: ({ isGroup, buildingId, expenditureId, planId }: IDeletePlanReq) => {
    if (isGroup) {
      return axios.delete(`/building/${buildingId}/groups/plans/${planId}/`);
    } else {
      return axios.delete(`/building/${buildingId}/estimate/items/${expenditureId}/intervals/${planId}/`);
    }
  },
  deleteSectionPlan: (buildingId: number | string, planId: number) => {
    return axios.delete(`/building/${buildingId}/estimate/sections/plan-intervals/${planId}/`);
  },
};
