import React from "react";

import MenuItem from "features/templates/NavigationBar/MenuItem";
import { useTranslation } from "react-i18next";

import { useLocationArray } from "utils/hooks/useLocationArray";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import icon from "images/icons/navigation/handbook.svg";

const HandBookButton = () => {
  const { t } = useTranslation();
  const locationArray = useLocationArray();
  const isActive = locationArray.includes("handbook");
  const routePrefix = useUrlModuleWithObjectId();
  const link = `/${routePrefix}/handbook/works`;

  return (
    <MenuItem /* @ts-ignore */
      isExists
      title={t("Справочники")}
      link={link}
      icon={icon}
      isDirectlyActive={isActive}
      //className={styles.link}
      isBottom
    />
  );
};

export default HandBookButton;
