import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IHandBook } from "./types";
import { IListResponseData } from "types/ListResponse";

export interface HandBookLocalEditings {
  /** Удалённые */
  deletions: Record<number, boolean>;
  /** Отредактированные */
  changes: Record<number, Partial<IHandBook>>;
  /** Отмеченные чекбоксом */
  checked: Record<number, boolean>;
  /** Новые добавляемые */
  new: Record<string, unknown>[];
}

interface HandBookState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  handbooks: Record<string, IListResponseData<IHandBook>>;
  /** Чтобы добавить строку в handbooks ^^ нужно пробрасывать key через интерфейс (непонятно зачем), поэтому добавлять будем в последний использованный для загрузки строк key  */
  _lastUsedKey: string;
  isEditing: boolean;
  editings: HandBookLocalEditings;
}

export const initialState: HandBookState = {
  invalidationKey: 0,
  loadings: {},
  handbooks: {},
  _lastUsedKey: '',
  isEditing: false,
  editings: {
    deletions: {},
    changes: {},
    checked: {},
    new: [],
  },
};

const handBookSlice = createSlice({
  name: "handBook",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    setItems: (state, action: PayloadAction<{ items: IListResponseData<IHandBook>; key: string }>) => {
      const { items, key } = action.payload;
      state.handbooks[key] = items;
      state._lastUsedKey = key;
    },
    setItemsMore: (state, action: PayloadAction<{ items: IListResponseData<IHandBook>; key: string }>) => {
      const { items, key } = action.payload;
      state.handbooks[key] = { ...state.handbooks[key], results: [...state.handbooks[key].results, ...items.results] };
      state._lastUsedKey = key;
    },
    updateItem: (state, action: PayloadAction<IHandBook>) => {
      state.handbooks = Object.fromEntries(
        Object.entries(state.handbooks).map(([key, obj]) => [
          key,
          {
            ...obj,
            results: obj.results.map((el) => {
              // @ts-ignore
              if (el.id === action.payload.id || el.id === action.payload._id) {
                return action.payload;
              }
              return el;
            }),
          },
        ])
      );
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      state.handbooks = Object.fromEntries(
        Object.entries(state.handbooks).map(([key, obj]) => [
          key,
          {
            ...obj,
            results: obj.results.filter((el) => {
              if (el.id === action.payload) {
                return false;
              }
              return true;
            }),
          },
        ])
      );
    },
    addItem: (state, action: PayloadAction<{ item: IHandBook; key: string }>) => {
      state.handbooks = {
        ...state.handbooks,
        [action.payload.key]: {
          ...state.handbooks[action.payload.key],
          results: (state.handbooks[action.payload.key].results || [])?.concat(action.payload.item),
        },
      };
    },
    clearEditings: (state) => {
      state.editings = {
        deletions: {},
        changes: {},
        checked: {},
        new: [],
      };
    },
    localDeleteItem: (state, action: PayloadAction<number>) => {
      state.editings = {
        ...state.editings,
        deletions: {
          ...state.editings.deletions,
          [action.payload]: true,
        },
      };
    },
    localChangeItem: (state, action: PayloadAction<{ id: number; change: Partial<IHandBook> }>) => {
      state.editings = {
        ...state.editings,
        changes: {
          ...state.editings.changes,
          [action.payload.id]: Object.assign(
            {},
            state.editings.changes[action.payload.id] || {},
            action.payload.change
          ),
        },
      };
    },
    localCheckItem: (state, action: PayloadAction<{ id: number; isChecked: boolean }>) => {
      state.editings = {
        ...state.editings,
        checked: {
          ...state.editings.checked,
          [action.payload.id]: action.payload.isChecked,
        },
      };
    },
    localAddItem: (state, action: PayloadAction<{ id: number, _new: true }>) => {
      state.editings = {
        ...state.editings,
        new: state.editings.new.concat(action.payload),
      };
    },
    localDeleteAddingItem: (state, action: PayloadAction<number>) => {
      state.editings = {
        ...state.editings,
        new: state.editings.new.filter((x) => x.id !== action.payload),
      };
    },
    clearLocalAddings: (state) => {
      state.editings = {
        ...state.editings,
        new: []
      };
    },
  },
});

export const handBookActions = handBookSlice.actions;

export const handBookReducer = handBookSlice.reducer;
