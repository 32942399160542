interface ITree {
  id: number;
  children?: ITree[];
}

export const findInTreeById = (tree: ITree[], targetId: number): ITree | null => {
  const stack = JSON.parse(JSON.stringify(tree));
  while (stack.length > 0) {
    const curr = stack.pop()!;
    if (curr.id === targetId) {
      return curr;
    }
    if (curr.children) {
      stack.push(...curr.children);
    }
  }
  return null;
};

export const findPathToNodeById = (tree: ITree[], targetId: number): ITree[] | null => {
  const stack: { node: ITree; path: ITree[] }[] = tree.map(node => ({ node, path: [node] }));

  while (stack.length > 0) {
    const { node, path } = stack.pop()!;

    if (node.id === targetId) {
      return path;
    }

    if (node.children) {
      for (const child of node.children) {
        stack.push({ node: child, path: [...path, child] });
      }
    }
  }

  return null;
};