import { useDispatch } from "react-redux";

import { handBookActions } from "pages/HandBook/model/slice";

import { useTypedSelector } from "app/store/typedUseSelector";
import AddButton from "shared/ui/controls/AddButton/AddButton";

import styles from "./HandBookAddButton.module.scss";

interface HandBookAddButtonProps {}

const HandBookAddButton: React.FC<HandBookAddButtonProps> = () => {
  const dispatch = useDispatch();
  const isEditing = useTypedSelector((state) => state.handBookReducer.isEditing);
  if (isEditing) return null;
  const addNewRow = () => dispatch(handBookActions.localAddItem({ _new: true, id: Math.random() }));
  return (
    <AddButton
      variant="solid"
      text="Добавить позицию"
      textPosition="left"
      onClick={addNewRow}
      className={styles.addButton}
    />
  );
};

export default HandBookAddButton;
