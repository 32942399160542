import cn from "classnames";
import moment from "moment";
import React, { useState } from "react";

import { IAosrDoc } from "redux/modules/common/building/journal/aosr/types";

import AosrDocumentAdd from "../../Aosr/AosrDocumentAdd/AosrDocumentAdd";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import FileViewer from "widgets/FileViewer/FileViewer";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import StatusConfirmedIcon from "images/icons/StatusConfirmedIcon";

import styles from "./AookExistingModal.module.scss";

interface IProps {
  isDisabled?: boolean;
  allDocs?: IAosrDoc[];
  //TODO types
  removeAosrDoc: any;
  openDeletingModal: any;
  aookDocsTypesOptions: any;
  deletingDoc: any;
  startCreatingDoc: any;
  deleteDocHandler: any;
  closeDeletingModal: any;
}

//TODO share this component between aosr and aook
const AookDocTable = ({
  isDisabled,
  allDocs,
  removeAosrDoc,
  openDeletingModal,
  aookDocsTypesOptions,
  deletingDoc,
  deleteDocHandler,
  startCreatingDoc,
  closeDeletingModal,
}: IProps) => {
  if (isDisabled) {
    return (
      <>
        {!!allDocs?.length && (
          <div className={cn(styles.doc, styles.docsHead)}>
            <div>Наименование документа</div>
            <div>Дата</div>
          </div>
        )}
        {allDocs?.map?.((el, i) => (
          <Row el={el} key={el.id} />
        ))}
      </>
    );
  }

  return (
    <div className={styles.docsContainer}>
      {allDocs?.map((el, i) => (
        <AosrDocumentAdd
          index={i}
          key={i}
          remove={removeAosrDoc}
          rowClassName={styles.docsRow}
          isEditMode
          onDirectlyDelete={openDeletingModal}
          item={el}
          typeOptions={aookDocsTypesOptions}
        />
      ))}
      <ConfirmModal
        title="Подтвердите удаление"
        isOpen={!!deletingDoc}
        onClose={closeDeletingModal}
        action={deleteDocHandler}
        variant="secondary"
      />
      <AddButton onClick={startCreatingDoc} text="Добавить" textPosition="left" className={styles.addButton} />
    </div>
  );
};

export default AookDocTable;

interface IRowProps {
  el: IAosrDoc;
}

const Row = ({ el }: IRowProps) => {
  const [fileViewerIndex, setFileViewerIndex] = useState(-1);

  return (
    <div className={styles.doc} key={el.id}>
      <div>{el.title}</div>
      <div>{moment(el.doc_date).format("DD.MM.YYYY")}</div>
      <div className={cn(styles.actionCell, styles.download)}>
        {el.listfiles?.length > 0 && <DownloadIcon onClick={() => setFileViewerIndex(0)} />}
      </div>
      <div className={styles.actionCell}>
        <StatusConfirmedIcon />
      </div>
      <FileViewer
        startIndex={fileViewerIndex}
        isOpen={fileViewerIndex >= 0}
        onClose={() => setFileViewerIndex(-1)}
        files={el.listfiles as any}
      />
    </div>
  );
};
