import cn from "classnames";
import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { objectDetailSelector } from "redux/modules/common/building/object/nowObject";
import { ISectionPlan } from "redux/modules/common/building/processTypes";

import IntervalGroupModal from "../../UI/_TODO/Expenditure/components/IntervalGroupModal/IntervalGroupModal";
import Approval from "../../UI/_TODO/WorkOrMaterialsModals/components/Approval/Approval";
import MaterialBlockInWork from "../../UI/_TODO/WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import NavBar from "../../UI/_TODO/WorkOrMaterialsModals/components/NavBar/NavBar";
import WorkListItem from "../../UI/_TODO/WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import WorkersList from "../../UI/_TODO/WorkOrMaterialsModals/components/WorkersList/WorkersList";
import ModalContentSplitter from "../../UI/atoms/ModalContentSplitter/ModalContentSplitter";
import Expandable from "../../UI/atoms/_TODO/Expandable/Expandable";
import ProductIntervalCardContent from "../../pages/Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";
import IntervalRelationsContent from "../../pages/Manufacturing/components/modals/ManufacturingModal/IntervalRelationsContent/IntervalRelationsContent";
import modalStyles from "../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModal.module.scss";
import ManufacturingModalBottom from "../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalBottom/ManufacturingModalBottom";
import ManufacturingModalContentFact from "../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalContentFact";
import ManufacturingModalContentPlan from "../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalContentPlan";
import PlanEditing from "../../pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/PlanEditing";
import useCanApprovePlan from "../../pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/useCanApprovePlan";
import {
  MAIN_PLAN_TAB,
  PLAN_TABS,
  RELATIONS_PLAN_TAB,
} from "../../pages/Manufacturing/components/modals/ManufacturingModal/constants";
import { manufacturingMaterialsStatusSwitcher } from "../../pages/Manufacturing/components/modals/ManufacturingModal/manufacturingModalStatusSwitcher";
import {
  ManufacturingModalType,
  useManufacturingModal,
} from "../../pages/Manufacturing/components/modals/ManufacturingModal/useManufacturingModal";
import { nextManufacturingModalType } from "../../pages/Manufacturing/components/modals/ManufacturingModal/utils";
import { INTERVAL_TYPES } from "../../pages/Manufacturing/constants";
import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import DayWorkerAndEvent from "components/UI/_TODO/WorkOrMaterialsModals/components/DayWorkerAndEvent/DayWorkerAndEvent";
import InfoItem from "components/UI/_TODO/WorkOrMaterialsModals/components/InfoItem/InfoItem";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";

import { Spinner } from "../../../shared/ui/atoms/Spinner/Spinner";
import ButtonBack from "../../../shared/ui/controls/ButtonBack/ButtonBack";
import TabBarNotLinks from "../../../shared/ui/controls/TabBar/TabBarNotLinks";
import ClosureDates from "../../../shared/ui/inputs/ClosureDates/ClosureDates";
import DoubleInput, { Measure } from "../../../shared/ui/inputs/DoubleInput/DoubleInput";
import ExpandableFileInput from "../../../shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import Executors from "../../../widgets/Executors/Executors";
import EditSectionPlan from "./EditSectionPlan/EditSectionPlan";
import { useEditSectionPlan } from "./useEditSectionPlan";
import { useGetSectionPlan } from "./useGetSectionPlan";
import ButtonBase from "shared/ui/controls/ButtonBase";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";

import {
  VIEW_CONSTRUCTING_EDIT_DIRECTIVE_PLAN,
  VIEW_CONSTRUCTING_EDIT_SECTION_PLAN,
} from "../../../constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_EDIT_DIRECTIVE_PLAN,
  VIEW_MANUFACTURING_EDIT_SECTION_PLAN,
} from "../../../constants/permissions/manufacturingPermissions";

import usePermission from "../../../hooks/usePermission";
import { useUrlModule } from "../../../utils/hooks/useUrlModule";

import { transformDigitToFinancial } from "../../../utils/formatters/transformDigitToFinancial";
import { percentsFormatter } from "../../../utils/percentsFormatter";

import styles from "./ManufacturingPlanSectionModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  intervaldata?: { id: number };
  objectId: string;
  externalData?: ISectionPlan;
  sectionId: number;
  onEditCallback?: () => void;
  modalType: ManufacturingModalType;
  onAddToJournalCallback?: () => void;
  onBackButtonClick?: () => void;
}

const ManufacturingPlanSectionModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  intervaldata,
  objectId,
  externalData,
  sectionId,
  onEditCallback,
  onAddToJournalCallback,
  modalType = "section",
  onBackButtonClick,
}) => {
  const type = INTERVAL_TYPES.plan;
  const innerIntervalId = externalData?.id || intervaldata?.id || -1;
  const [isEditing, setIsEditing] = React.useState(false);
  const isNew_close_dates = useSelector(objectDetailSelector)?.new_close_dates;
  const { planData, getSectionPlanFetcher } = useGetSectionPlan(
    objectId,
    innerIntervalId,
    isOpen && !externalData,
    modalType
  );

  const innerData = { ...(externalData || {}), ...(planData || {}) } || null;
  /* @ts-ignore */
  const { approveHandler } = useEditSectionPlan(innerData, getSectionPlanFetcher, () => {}, objectId);

  const canApprovePlan = useCanApprovePlan({
    objectId,
    approvals: innerData?.approvals,
    isSection: true,
    isActive: isOpen,
  });

  const urlModule = useUrlModule();

  const hasEditDirectivePlanInConstructing = usePermission(VIEW_CONSTRUCTING_EDIT_DIRECTIVE_PLAN);
  const hasEditSectionPlanInConstructing = usePermission(VIEW_CONSTRUCTING_EDIT_SECTION_PLAN);
  const hasEditDirectivePlanInManufacturing = usePermission(VIEW_MANUFACTURING_EDIT_DIRECTIVE_PLAN);
  const hasEditSectionPlanInManufacturing = usePermission(VIEW_MANUFACTURING_EDIT_SECTION_PLAN);

  const hasEditPermission =
    (urlModule === "constructing" &&
      ((modalType === "lsr" && hasEditDirectivePlanInConstructing) ||
        (modalType === "section" && hasEditSectionPlanInConstructing))) ||
    (urlModule === "objects" &&
      ((modalType === "lsr" && hasEditDirectivePlanInManufacturing) ||
        (modalType === "section" && hasEditSectionPlanInManufacturing)));

  const {
    setIsReviewing,
    closeHandler,
    interval,
    statusText,
    statusIcon,
    setIsSharing,
    files,
    fileUploadHandler,
    fileRemoveHandler,
    isEditingPlan,
    setIsEditingPlan,
    closePlanEditingHandler,
    isLoading,
    hasRelations,
    planTab,
    isAddingNew,
    setIsAddingNew,
    list,
    handleListBackButton,
    setPlanTab,
    hasEditRelationsPermission,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
    canEditPlan,
    modalMaterials,
    approveHandler: selectedIntervalApproveHandler,
    planIsInFuture: selectedIntervalIsInFuture,
    intervalClickHandler,
    statusColor,
    isGroup,
  } = useManufacturingModal({
    isOpen,
    type,
    objectId,
    expenditureId: sectionId || innerIntervalId,
    sectionId: sectionId || innerIntervalId,
    date_start: innerData?.start_at || "",
    date_end: innerData?.end_at || "",
    modalType,
    onClose,
    intervaldata: innerData,
    isPlanSection: true,
  });

  const { plannedPercent, plannedAmount } = useMemo(() => {
    if (!innerData?.close_dates) return { plannedPercent: 0, plannedAmount: 0 };
    let plannedPercent = 0;
    let plannedAmount = 0;
    innerData.close_dates.forEach((date) => {
      plannedPercent += parseFloat(date.percent || "") || 0;
      plannedAmount += parseFloat(date.amount || "") || 0;
    });
    return { plannedPercent, plannedAmount };
  }, [innerData?.close_dates]);

  const isWorkListShown = !!list?.length;

  const relationsContent = (interval: ISectionPlan | typeof innerData) =>
    !interval ? null : (
      <>
        {hasRelations && (
          <TabBarNotLinks
            tabs={PLAN_TABS}
            activeId={planTab}
            onClick={setPlanTab}
            className={modalStyles.relationsTabs}
          />
        )}
        {planTab === RELATIONS_PLAN_TAB && (
          <IntervalRelationsContent
            hasEditPermission={hasEditRelationsPermission}
            intervalId={interval.id as number}
            projectId={+objectId}
            relationsFromCurrentInterval={relationsFromCurrentInterval}
            relationsToCurrentInterval={relationsToCurrentInterval}
            intervalStartAt={interval.start_at as string}
            intervalEndAt={interval.end_at as string}
            onAddRelationCallback={onEditCallback}
          />
        )}
      </>
    );

  if (isGroup && !!interval?.id) {
    return (
      <IntervalGroupModal
        isOpen
        onClose={closeHandler}
        onBackButtonClick={handleListBackButton}
        expenditureId={interval.id}
        expenditure={interval}
        sectionName={interval.group?.section_name}
        activeModule={"plans"}
        date_start={interval.start_at}
        date_end={interval.end_at}
        objectId={objectId}
        sectionId={sectionId}
      />
    );
  }

  if (isEditing && (interval || innerData)) {
    const planData = !!interval?.start_at ? interval : innerData;
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={onClose}
        name={planData?.section_name}
        modalClassname={styles.editSectionPlanModal}
      >
        <EditSectionPlan
          onClose={() => setIsEditing(false)}
          planData={planData}
          dataRefetcher={getSectionPlanFetcher}
          objectId={objectId}
          onEditCallback={onEditCallback}
          plannedPercent={plannedPercent}
          plannedAmount={plannedAmount} /* @ts-ignore */
          budgetData={innerData.section_estimate_amount}
          onCloseWholeModal={onClose}
        />
      </ModalContainer>
    );
  }

  if (isEditingPlan) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification} //@ts-ignore
        statusColor={statusColor}
        statusText={statusText}
        statusIcon={statusIcon}
      >
        <ButtonBack onClick={() => setIsEditingPlan(false)} className={modalStyles.back} />
        {/* @ts-ignore */}
        <PlanEditing /* @ts-ignore */
          planCreator={interval?.author}
          planCreatingDate={interval?.created_at}
          productId={interval?.expenditure_id}
          buildingId={+objectId}
          intervalId={interval?.id}
          planCount={interval?.count}
          planDateStart={interval?.start_at}
          planDateEnd={interval?.end_at}
          onClose={closePlanEditingHandler}
          files={files}
          onDeleteFiles={fileRemoveHandler}
          onUploadFiles={fileUploadHandler}
          defaultMaterials={interval?.planned_materials_list}
          defaultWorkers={interval?.workers}
          defaultWorkersCounts={interval?.count_workers_data}
          sectionId={sectionId}
          paymentDate={interval?.payment_date}
          expemdituresIds={[interval?.expenditure_id]}
          closeWholeModal={onClose}
        />
      </ModalContainer>
    );
  }

  if (interval?.id && !isLoading && modalType !== "lsr") {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification} //@ts-ignore
        statusColor={statusColor}
        statusText={statusText}
        statusIcon={statusIcon}
        isFixedHeader={hasRelations && planTab === RELATIONS_PLAN_TAB}
      >
        {isAddingNew && (
          <NavBar
            isExist
            text="Вернуться к работе"
            onClick={() => setIsAddingNew(false)}
            className={modalStyles.back}
          />
        )}
        {isAddingNew && (
          <ProductIntervalCardContent //@ts-ignore
            objectId={intervaldata.building_id}
            handleClose={() => setIsAddingNew(false)}
            directlySection={interval?.section_id}
            directlyWork={interval?.expenditure_id}
            isOpen={isOpen}
            onAddToJournalCallback={onAddToJournalCallback}
          />
        )}
        {!isAddingNew && (
          <>
            <NavBar
              text={"Все планы"}
              isExist={isWorkListShown}
              onClick={handleListBackButton}
              className={modalStyles.back}
            />
            {relationsContent(interval)}
            {planTab === MAIN_PLAN_TAB && (
              <>
                {type === INTERVAL_TYPES.plan && (
                  <ManufacturingModalContentPlan
                    planCreator={interval?.author}
                    planCreatingDate={interval?.created_at}
                    measure={interval?.measure}
                    dateStart={interval?.start_at}
                    dateEnd={interval?.end_at}
                    projectAmount={interval?.work_amount_with_materials}
                    projectFullAmount={interval?.expenditure_amount}
                    projectFullCount={interval?.expenditure_count}
                    planCount={interval?.count}
                    planIsInWork={interval?.work_status === "actived"}
                    approvals={interval?.approvals}
                    closureDate={interval?.payment_date}
                  />
                )}
                {type !== INTERVAL_TYPES.plan && (
                  <ManufacturingModalContentFact
                    dateStart={interval?.start_at}
                    dateEnd={interval?.end_at}
                    measure={interval?.measure}
                    passingAuthor={interval?.author}
                    passingDate={interval?.created_at}
                    passingCount={interval?.count}
                    investedAmount={interval?.work_amount}
                    acceptedAmount={interval?.accepted?.length > 0 && interval.accepted[0].amount}
                    acceptedAuthor={interval?.accepted?.length > 0 && interval.accepted[0].approve_user}
                    acceptedCount={interval?.accepted?.length > 0 && interval.accepted[0].count}
                    acceptedDate={interval?.accepted?.length > 0 && interval.accepted[0].created_at}
                    planCreator={interval?.from_interval?.author}
                    planCreatingDate={interval?.from_interval?.created_at}
                    planCount={interval?.from_interval?.count}
                    projectAmount={interval?.from_interval?.work_amount}
                    planApprover={interval?.from_interval?.approved_user}
                    planApprovingDate={interval?.from_interval?.approved_at}
                  />
                )}

                <div className={modalStyles.pair}>
                  <div className={modalStyles.info}>
                    <b>Раздел:</b>
                    <span>{interval?.section_name}</span>
                  </div>
                </div>
                <WorkersList workers={interval?.workers} />
                {!!modalMaterials?.length && (
                  <Expandable title={`Материалы: ${modalMaterials.length}`}>
                    {modalMaterials.map((el: any, index: number) => (
                      <MaterialBlockInWork
                        name={el.stock_using_material?.product_building?.name}
                        measure={el.stock_using_material?.product_building?.measure}
                        count={el.count}
                        amount={el.amount}
                        key={el.id} //@ts-ignore
                        statusColor={manufacturingMaterialsStatusSwitcher(interval, index).color}
                        statusText={manufacturingMaterialsStatusSwitcher(interval, index).text}
                      />
                    ))}
                  </Expandable>
                )}
                {!!interval?.planned_materials_list?.length && (
                  <Expandable title={`Материалы: ${interval?.planned_materials_list.length}`}>
                    {interval?.planned_materials_list.map((el: any) => (
                      <MaterialBlockInWork
                        name={el.material?.name}
                        measure={el.material?.measure}
                        count={el.count}
                        amount={el.material?.estimate_expenditure?.estimate_amount}
                        key={el.id}
                        statusColor="blue"
                        statusText="План"
                      />
                    ))}
                  </Expandable>
                )}
                {!!interval?.count_planned_services?.length && (
                  <Expandable title={`Машины и Механизмы: ${interval?.count_planned_services.length}`}>
                    {interval?.count_planned_services.map((el: any) => (
                      <MaterialBlockInWork
                        key={el.id}
                        name={el.service?.name}
                        measure={el.service?.measure}
                        count={el.count}
                        amount={el.amount}
                        statusColor="blue"
                        statusText="План"
                      />
                    ))}
                  </Expandable>
                )}
                {!!files?.length && (
                  <ExpandableFileInput
                    files={files || []}
                    canExtractFiles
                    canDeleteFiles={false}
                    canUploadFiles={false}
                  />
                )}
                <ManufacturingModalBottom
                  interval={interval}
                  isPlan={type === INTERVAL_TYPES.plan}
                  canEditPlan={canEditPlan}
                  canApprovePlan={!!canApprovePlan}
                  planIsInFuture={selectedIntervalIsInFuture}
                  onAddToJournal={() => setIsAddingNew(true)}
                  onApprove={selectedIntervalApproveHandler}
                  onEdit={() => setIsEditingPlan(true)}
                  onSetSharing={() => setIsSharing(true)}
                  onSetReviewingShared={() => setIsReviewing(true)}
                />
              </>
            )}
          </>
        )}
      </ModalContainer>
    );
  }

  if (interval?.id && !isLoading && modalType === "lsr") {
    return (
      <ManufacturingPlanSectionModal
        isOpen
        onClose={onClose}
        intervaldata={interval}
        objectId={objectId}
        sectionId={sectionId}
        onEditCallback={onEditCallback}
        onAddToJournalCallback={onAddToJournalCallback}
        modalType={nextManufacturingModalType(modalType)}
        onBackButtonClick={handleListBackButton}
      />
    );
  }

  return (
    <ModalContainer isOpen={isOpen} onClose={closeHandler} name={innerData?.section_name} isFixedHeadline>
      {isLoading && <Spinner />}
      {!isLoading && innerData && (
        <>
          {relationsContent(innerData)}
          {planTab === MAIN_PLAN_TAB && (
            <>
              {onBackButtonClick && (
                <NavBar onClick={onBackButtonClick} className={styles.back} isExist text={"Все планы"} />
              )}
              {/* @ts-ignore */}
              <DayWorkerAndEvent date={innerData?.created_at} label="Создал" author={innerData?.author} />
              <div className={cn(styles.pair, styles.centered)}>
                <b>Период выполнения:</b>
                <CalendarRange
                  defaultDateStart={innerData?.start_at}
                  defaultDateEnd={innerData?.end_at}
                  disabled
                  classNameSelect={styles.calendar}
                />
              </div>
              {!isNew_close_dates && (
                <InfoItem
                  label="Бюджет (по проекту)" /* @ts-ignore */
                  value={`${transformDigitToFinancial(innerData.section_estimate_amount, {
                    withCurrencySign: true,
                    withFloat: true,
                  })}`}
                />
              )}
              {!innerData?.parent_id && !!innerData?.close_dates?.length && (
                <Expandable title={`Плановые даты закрытия:`} className={styles.element}>
                  <ClosureDates
                    closureDates={innerData.close_dates}
                    canEdit={false} /* @ts-ignore */
                    isNew_close_dates={isNew_close_dates}
                    canSave={false} /* @ts-ignore */
                    budgetData={innerData.section_estimate_amount}
                  />
                </Expandable>
              )}
              {!!innerData?.parent_id && (
                <>
                  <div className={cn(styles.pair, styles.centered)}>
                    <b>Запланировано выполнение:</b>
                    <DoubleInput
                      firstValueMeasure={Measure.percent} /* @ts-ignore */
                      firstValue={percentsFormatter(parseFloat(plannedPercent || ""))?.replace("%", "")}
                      firstInputSize={1}
                      secondValueMeasure={Measure.currency}
                      secondValue={plannedAmount?.toString()}
                      secondInputSize={2}
                      onChangeFirstValue={() => {}}
                      onChangeSecondValue={() => {}}
                      className={isNew_close_dates ? styles.inputBudget : undefined}
                      isFirstDisabled
                      isSecondDisabled
                      isFullHeight
                      variant={"outline"}
                    />
                  </div>
                  {isNew_close_dates && (
                    <div className={cn(styles.field, styles.plannedCompletion)}>
                      <b>Бюджет (по проекту):</b>
                      <InputBase
                        className={styles.inputBudget}
                        isButtonActive
                        value={innerData.section_estimate_amount}
                        valueType={VALUE_TYPES.PRICE} /* @ts-ignore */
                        numberTransformOptions={{ withCurrencySign: true }}
                        disabled
                      />
                    </div>
                  )}
                  {!!innerData?.close_dates?.length && (
                    <Expandable title={`Плановые даты закрытия:`} className={styles.element}>
                      <ClosureDates
                        closureDates={innerData.close_dates}
                        canEdit={false} /* @ts-ignore */
                        isNew_close_dates={isNew_close_dates}
                        canSave={false} /* @ts-ignore */
                        budgetData={innerData.section_estimate_amount}
                      />
                    </Expandable>
                  )}
                  {!!innerData?.posts?.length && (
                    <Executors
                      className={styles.element}
                      tableClassName={styles.executorsTable}
                      addButtonClassName={styles.addExecutorBtn}
                      executors={innerData?.posts || []}
                      canEdit={false}
                      onEdit={() => {}}
                      onDelete={() => {}}
                      onAddExecutor={() => {}}
                      availableRanksByPosts={{}}
                      availablePosts={[]}
                    />
                  )}
                  {!!innerData?.approvals?.length && !!innerData?.created_at && (
                    <Approval approvals={innerData?.approvals} planCreatingDate={innerData?.created_at} />
                  )}
                </>
              )}
              {isWorkListShown && (
                <div>
                  {list.map(
                    (
                      el: any,
                      index: number //@ts-ignore
                    ) => {
                      const isGroup = !!el.group;
                      return (
                        <React.Fragment key={el.id}>
                          {(index === 0 || el.start_at !== list[index - 1]?.start_at) && (
                            <ModalContentSplitter label={moment(el.start_at).format("DD.MM.YY")} />
                          )}
                          <WorkListItem
                            type={type}
                            onClick={() =>
                              intervalClickHandler(el.id, el.expenditure_id || el.group?.id || el.section_id, isGroup)
                            }
                            name={el.expenditure_name || el.section_name || el.group?.name}
                            passAmount={
                              el.completed_amount ||
                              el.work_amount_with_materials ||
                              el.section_estimate_amount ||
                              el.group?.amount
                            }
                            passCount={el.count || el.group?.count}
                            passDate={el.created_at}
                            passName={isGroup ? el.author?.name : el.author}
                            acceptAmount={el.accepted?.length ? el.accepted[0].amount : 0}
                            acceptCount={el.accepted?.length ? el.accepted[0].count : 0}
                            acceptDate={el.accepted?.length ? el.accepted[0].created_at : ""}
                            acceptName={el.accepted?.length ? el.accepted[0].approve_user : ""}
                            measure={el.measure || el.group?.measure}
                            hideAccepted={!el.accepted?.length}
                            modalType={modalType}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              )}
              <BottomControls
                isDoubleBtns
                isExists={
                  canApprovePlan ||
                  (canEditPlan && ((hasEditPermission && !innerData?.parent_id) || !!innerData?.parent_id))
                }
              >
                {((hasEditPermission && !innerData?.parent_id) || !!innerData?.parent_id) && canEditPlan && (
                  <ButtonBase secondary onClick={() => setIsEditing(true)}>
                    Редактировать
                  </ButtonBase>
                )}
                {!!innerData?.parent_id && canApprovePlan && (
                  <ButtonBase primary onClick={approveHandler}>
                    Согласовать
                  </ButtonBase>
                )}
              </BottomControls>
            </>
          )}
        </>
      )}
    </ModalContainer>
  );
};

export default ManufacturingPlanSectionModal;
