import { debounce } from "lodash";
import { useEffect } from "react";

const getCheckboxElement = (e: Event) => {
  const checkbox = ((e.target as ChildNode | null)?.previousSibling ||
    (e.target as HTMLElement | null)?.closest("input[type='checkbox']")) as HTMLElement | null;
  if (!checkbox || checkbox.tagName !== "INPUT") return null;
  return checkbox;
};

export const useCheckboxesToggleHold = () => {
  useEffect(() => {
    let mousedownStamp = 0;
    let startCheckbox: HTMLElement | null = null;

    const handleMouseDown = (e: Event) => {
      if ((e.target as HTMLElement | null)?.closest(".checkbox")) {
        mousedownStamp = Math.random();
        startCheckbox = getCheckboxElement(e);
      }
    };

    const handleMouseUp = () => {
      mousedownStamp = 0;
    };

    const handleMouseMove = debounce((e: Event) => {
      if (!mousedownStamp) return;
      const checkbox = getCheckboxElement(e);
      if (!checkbox || checkbox === startCheckbox) return;
      if (startCheckbox?.getAttribute("data-mousedown") !== mousedownStamp.toString()) {
        startCheckbox?.click();
        startCheckbox?.setAttribute("data-mousedown", mousedownStamp.toString());
      }
      if (checkbox.getAttribute("data-mousedown") === mousedownStamp.toString()) return;
      checkbox.setAttribute("data-mousedown", mousedownStamp.toString());
      checkbox.click();
    }, 4);

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);
};
